import { Group } from "../../../shared/src/dynamo";
import { TaskId } from "../../../shared/src/jobs-v2";
import { SCMType } from "../../../shared/src/scm-type";
import { UISimpleProduct } from "../../../shared/src/ui-models/products-v2/ui-simple-product";
import { ApiBase } from "./api-base";

class ExportsApi extends ApiBase {
  public constructor() {
    super({
      name: "Exports",
      pathPrefix: "exports"
    });
  }

  /**
   * Create an export generation task
   * @param type The export type
   * @param format The export format
   * @param sections The sections to include in the export
   * @param group The group to export
   * @returns The export generation task ID
   */
  public async create(type: string, format: string, sections: string[], version: number, product: UISimpleProduct | Pick<Group<SCMType>, "resources" | "scm" | "name">): Promise<TaskId> {
    const res = await this.client.post<TaskId>(`${[type, format].map(encodeURIComponent).join("/")}`, product, {
      params: {
        sections,
        productVersion: version
      }
    });
    const taskId = res.data;
    return taskId;
  }
}

export const Exports = new ExportsApi();
