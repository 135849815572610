import { SortKeys } from "../components/findings-table/finding-utils";
import { Dialog } from "../state/dialog-state";
import { DismissedRiskReportItem } from "$/dynamo";
import { CreateDismissItemRequest } from "$/interfaces/ui-api/request";
import { SCMType } from "$/scm-type";
import { Dismissals as Api, CodeRisk as RiskApi } from "@/api";
import DismissReasonConfirmation from "@/components/risks/dismiss-reason-confirmation.vue";
import { AllStatuses, CODE_RISK_STATUSES, DISMISSAL_OPTIONS } from "@/pages/risks/code/code-risk-constants";
import { AnalyticsService } from "@/services/analytics-service";
import { Insights } from "@/state";
import { RiskReports } from "@/state";

export const Dismissals = new (class DismissalsState {
  public githubDismissedItems: DismissedRiskReportItem[] | null = null;
  public adoDismissedItems: DismissedRiskReportItem[] | null = null;

  /**
   * Used to optimistically remove dismissed items from the reports while the insights and risk reports are generating
   */
  public recentlyDismissedItems = new Map<string, DismissedRiskReportItem>();

  public async init() {
    const [ado, github] = await Promise.all([Api.get(SCMType.AZURE_DEVOPS), Api.get(SCMType.GITHUB)]);
    this.adoDismissedItems = ado;
    this.githubDismissedItems = github;
  }

  public async dismissItem(dismissItemRequest: CreateDismissItemRequest) {
    const createdDismissals = await Api.create(dismissItemRequest);
    createdDismissals.forEach((dismissal) => {
      this.recentlyDismissedItems.set(dismissal.riskReportItemSCMTypeAndItemTypeAndId, dismissal);
    });
    this.silentlyGenerateInsightsAndReports().catch((e) => {
      console.error("An error occurred while generating insights", e);
    });
  }

  public async undismissItem(dismissedItemKey: string) {
    await Api.delete(dismissedItemKey);
    this.recentlyDismissedItems.delete(dismissedItemKey);
    this.silentlyGenerateInsightsAndReports().catch((e) => {
      console.error("An error occurred while generating insights", e);
    });
  }

  private async silentlyGenerateInsightsAndReports() {
    await Insights.generateInsights({ withSBOM: false, withBusinessImportance: false })
      .then((_) => {
        Insights.init(true);
        RiskReports.init({ silent: true });
      })
      .catch((e) => {
        console.error("An error occurred while generating insights", e);
      });
  }

  public readonly dismissalOptions = DISMISSAL_OPTIONS.map((option) => ({
    ...option,
    ...CODE_RISK_STATUSES[option.value]
  }));

  private async reasonDismiss(showDialog: boolean, optionSelected: string): Promise<string | null> {
    if (!showDialog) {
      return "";
    }
    return await Dialog.open<string | null>({
      content: DismissReasonConfirmation,
      bindData: {
        optionSelected: optionSelected || "",
        description: "Please confirm you would like to dismiss the selected items for the following reason:",
        dismissButtonText: "Confirm"
      },
      title: "Confirm Resolution"
    });
  }

  public async dismissFindings(sortKeys: SortKeys[], option: AllStatuses): Promise<void> {
    const optionSelected = this.dismissalOptions.find((item) => item.value === option);
    if (!optionSelected) {
      throw new Error("Selected option is not part of dismissal options");
    }
    const dismissReason: string | null = await this.reasonDismiss(optionSelected.showDialog, optionSelected.text);

    if (typeof dismissReason !== "string") {
      return;
    }
    AnalyticsService.logEvent("Dismiss Code Risk Finding", undefined, { group: "Engagement" });

    await Promise.all(sortKeys.map((item) => Api.updateDismissalStatus(option, item, dismissReason || undefined)));
  }
})();
