import { uuid } from "$/ui-models/uuid";
import { CreateOwnersParameters } from "$/ui-models/products-v2/ui-product-owner";
import { ApiBase } from "./api-base";

export class ProductOwnersApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "products", name: "ProductOwnersApi" });
  }

  public async addOwners(productId: uuid, owners: CreateOwnersParameters): Promise<void> {
    await this.client.post(`${productId.encodeURI()}/owners`, owners);
  }

  public async addSecurityChampion(productId: uuid, identityId: uuid): Promise<void> {
    const data: CreateOwnersParameters = { securityChampions: [{ identityId }] };
    await this.client.post(`${productId.encodeURI()}/owners`, data);
  }

  public async addProductOwner(productId: uuid, identityId: uuid, comment?: string): Promise<void> {
    const data: CreateOwnersParameters = { productOwners: [{ identityId, comment }] };
    await this.client.post(`${productId.encodeURI()}/owners`, data);
  }

  public async deleteSecurityChampion(productId: uuid, identityId: uuid): Promise<void> {
    await this.client.delete(`${productId.encodeURI()}/owners/securityChampions/${identityId.encodeURI()}`);
  }

  public async deleteProductOwner(productId: uuid, identityId: uuid): Promise<void> {
    await this.client.delete(`${productId.encodeURI()}/owners/productOwners/${identityId.encodeURI()}`);
  }

  public async updateProductOwnerComment(productId: uuid, identityId: uuid, comment: string): Promise<void> {
    await this.client.patch(`${productId.encodeURI()}/owners/productOwners/${identityId.encodeURI()}`, { comment });
  }
}
