
<v-container class="component dismiss-reason-confirmation pt-7" fluid>
  <v-card elevation="0">
    <v-card-text>
      <span>
        <span v-html-sanitize="description"></span>
        <b class="text-primary">{{ optionSelected }}</b>
      </span>
      <br />
      <br />
      Additional details:<br />
      <v-textarea rows="3" outlined v-model="reason" dense hide-details></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="dismiss()"> {{ dismissButtonText }} </v-btn>
      <v-btn text @click="closeDialog()"> Cancel </v-btn>
    </v-card-actions>
  </v-card>
</v-container>
