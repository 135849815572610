<template>
  <v-container fluid class="component select-dialog-content">
    <v-text-field v-if="longList" clearable dense v-model.trim="search" label="Search" autofocus></v-text-field>

    <v-list dense :style="`min-height: ${minHeight}; width: ${initialWidth}`" ref="list">
      <v-list-item-group v-model="selectedItem" color="success">
        <template v-if="options.length">
          <v-list-item :disabled="option.disabled" dense v-for="(option, idx) of pagedOptions" :key="idx" @click.stop="onClick(option)">
            <v-list-item-icon v-if="option.icon" class="side-icon">
              <v-icon>{{ option.icon }}</v-icon>
            </v-list-item-icon>
            <v-avatar size="24" v-if="option.avatar" class="avatar">
              <img :src="option.avatar" alt="" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ option.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="option.description">{{ option.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon v-if="option.iconRight" class="right-icon">
              <v-icon>{{ option.iconRight }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item dense disabled>
            <v-list-item-content>
              <v-list-item-title>No options available</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <div v-if="longList" class="float-end">
      <small> {{ start + 1 }}-{{ end }} of {{ total }} </small>
      <v-btn icon @click.stop.prevent="prevPage" title="Previous" aria-label="Previous page" :disabled="page <= 0">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon @click.stop.prevent="nextPage" title="Next" aria-label="Next page" :disabled="page >= pages - 1">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { SelectOption } from "@/state/dialog-state";

export interface SelectDialogArgs {
  value: unknown | null;
}

@Component
export default class SelectDialogContent extends Vue {
  protected initialWidth = "unset";
  protected page = 0;
  protected search: string | null = null;

  @Prop({
    type: Object,
    required: true
  })
  protected readonly args!: SelectDialogArgs;

  @Prop({
    type: Array,
    required: true
  })
  protected readonly options!: SelectOption<unknown>[];

  @Prop({
    type: Number,
    default: 10
  })
  protected readonly itemsPerPage!: number;

  @Prop({
    type: Function,
    required: true
  })
  protected readonly resolve!: (option: SelectDialogArgs) => void;

  @Prop({
    type: Function,
    default: (a: SelectOption<unknown>, b: SelectOption<unknown>) => a.title.localeCompare(b.title)
  })
  private readonly sorter!: (a: SelectOption<unknown>, b: SelectOption<unknown>) => number;

  @Prop({
    type: Boolean,
    default: false
  })
  private readonly preSorted!: boolean;

  protected get longList(): boolean {
    return this.options.length > this.itemsPerPage;
  }

  protected get sortedOptions(): SelectOption<unknown>[] {
    return this.preSorted ? this.options : this.options.sort((a, b) => this.sorter(a, b));
  }

  protected get filteredOptions(): SelectOption<unknown>[] {
    const search = (this.search || "").toLowerCase();
    return search ? this.sortedOptions.filter((o) => o.title.toLowerCase().includes(search) || o.description?.toLowerCase().includes(search)) : this.sortedOptions;
  }

  protected get start(): number {
    const total = this.filteredOptions.length;
    const perPage = this.itemsPerPage;
    return total < perPage ? 0 : Math.min(this.page * perPage, total);
  }

  protected get end(): number {
    const total = this.filteredOptions.length;
    const perPage = this.itemsPerPage;
    return total < perPage ? total : Math.min((this.page + 1) * perPage, total);
  }

  protected get total(): number {
    return this.filteredOptions.length;
  }

  protected get pages(): number {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  protected get minHeight(): string {
    const lineHeight = this.options.some((o) => o.description) ? 50 : 40;
    return `${lineHeight * Math.min(this.options.length, this.itemsPerPage) + 16}px`;
  }

  protected get pagedOptions(): SelectOption<unknown>[] {
    return this.filteredOptions.slice(this.start, this.end);
  }

  protected get selectedItem(): number {
    const value = this.args.value;
    return this.pagedOptions.findIndex((o) => o.value === value);
  }

  protected set selectedItem(value: number) {
    this.args.value = this.pagedOptions[value]?.value;
  }

  public async mounted(): Promise<void> {
    await this.$nextTick();
    const list = this.$refs["list"] as Vue;
    const el = list.$el;
    if (el) {
      this.fixatePaginationWidth(el);
      this.focusSelected(el);
    }
  }

  private fixatePaginationWidth(el: Element): void {
    const initialWidth = el.clientWidth;
    this.initialWidth = initialWidth ? `${initialWidth}px` : "unset";
  }

  private focusSelected(el: Element): void {
    const item = el.querySelector(".v-list-item.v-item--active.v-list-item--active") as HTMLElement;
    item?.focus();
  }

  protected prevPage(): void {
    this.page = Math.max(0, this.page - 1);
  }

  protected nextPage(): void {
    this.page = Math.min(Math.ceil(this.total / this.itemsPerPage) - 1, this.page + 1);
  }

  protected onClick(option: SelectOption<unknown>): void {
    this.args.value = option;
    this.resolve(this.args);
  }
}
</script>

<style lang="scss">
.component.select-dialog-content {
  .side-icon {
    margin-right: 1rem !important;
  }
  .avatar {
    margin-right: 8px;
  }
  .right-icon {
    float: right;
  }
}
</style>
