import { ApiBase } from "@/api/api-base";
import { FindingCount } from "$/ui-models/dashboard/finding-count";
import { BusinessImportanceLabel } from "$/business-importance";
import { DashboardFilter, DashboardSort } from "$/ui-models/dashboard/dashboard-filter";
import { AxiosRequestConfig } from "axios";
import { Dashboard2Activity } from "$/dashboard/dashboard-response";
import { DashboardProductsList } from "$/ui-models/dashboard/ui-dashboard-product";
import { UIDashboardInfo } from "$/ui-models/dashboard/ui-dashboard-info";
import { UIDashboardInfoDTO } from "$/ui-models/dashboard/ui-dashboard-info";
import {
  UIDashboardHistoryBySeverityDTO,
  UIDashboardHistoryByStatusDTO,
  UIDashboardHistoryByProductDTO,
  UIDashboardHistoryBySeverity,
  UIDashboardHistoryByStatus,
  UIDashboardHistoryByProduct
} from "$/ui-models/dashboard/ui-dashboard-history";

interface GetProductOptions {
  page: number;
  pageSize: number;
  search?: string;
  filter: DashboardFilter;
  sort: DashboardSort;
  silent?: boolean;
}

class DashboardApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "dashboard", name: "DashboardApi" });
  }

  public async getFindingCounts(minImportance: BusinessImportanceLabel, search?: string): Promise<FindingCount[]> {
    const url = await this.getUrl("counts");
    const result = await this.client.get<FindingCount[]>(url.toString(), {
      params: {
        minImportance,
        search: search || undefined
      }
    });
    return result.data;
  }

  public async getProducts({ page, pageSize, search, filter, sort, silent = false }: GetProductOptions): Promise<DashboardProductsList> {
    const url = await this.getUrl("products");
    const config: AxiosRequestConfig = {
      url: url.toString(),
      method: "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };
    const result = await this.client.request<DashboardProductsList>(config);
    return result.data;
  }

  public async getActivity({ filter, search, silent = false }: Pick<GetProductOptions, "filter" | "search" | "silent">): Promise<Dashboard2Activity[]> {
    const url = await this.getUrl("activity");
    const config: AxiosRequestConfig = {
      url: url.toString(),
      method: "POST",
      silent,
      data: {
        filter,
        search
      }
    };
    const result = await this.client.request<Dashboard2Activity[]>(config);
    return result.data;
  }

  public async getHistoryByProduct(filter: DashboardFilter, search: string): Promise<UIDashboardHistoryByProduct[]> {
    const url = await this.getUrl("history/product");
    const config: AxiosRequestConfig = {
      url: url.toString(),
      method: "POST",
      data: {
        filter,
        search
      }
    };
    const result = await this.client.request<UIDashboardHistoryByProductDTO[]>(config);
    return result.data.map((data) => new UIDashboardHistoryByProduct(data));
  }

  public async getHistoryByStatus(filter: DashboardFilter, search: string): Promise<UIDashboardHistoryByStatus[]> {
    const url = await this.getUrl("history/status");
    const config: AxiosRequestConfig = {
      url: url.toString(),
      method: "POST",
      data: {
        filter,
        search
      }
    };
    const result = await this.client.request<UIDashboardHistoryByStatusDTO[]>(config);
    return result.data.map((data) => new UIDashboardHistoryByStatus(data));
  }

  public async getHistoryBySeverity(filter: DashboardFilter, search: string): Promise<UIDashboardHistoryBySeverity[]> {
    const url = await this.getUrl("history/severity");
    const config: AxiosRequestConfig = {
      url: url.toString(),
      method: "POST",
      data: {
        filter,
        search
      }
    };
    const result = await this.client.request<UIDashboardHistoryBySeverityDTO[]>(config);
    return result.data.map((data) => new UIDashboardHistoryBySeverity(data));
  }

  public async getInfo(): Promise<UIDashboardInfo> {
    const url = await this.getUrl("info");
    const config: AxiosRequestConfig = {
      url: url.toString(),
      method: "GET"
    };
    const result = await this.client.request<UIDashboardInfoDTO>(config);
    return new UIDashboardInfo(result.data);
  }
}

export const Dashboard = new DashboardApi();
