export interface UIDashboardInfoDTO {
  lastSnapshot: string | null;
}

export class UIDashboardInfo {
  public lastSnapshot: Date | null;

  public constructor(dto: UIDashboardInfoDTO) {
    this.lastSnapshot = dto.lastSnapshot ? new Date(dto.lastSnapshot) : null;
  }
}
