import { PolicyActionInstantMessageMapped } from "../../../shared/src/interfaces/policies";
import { uuid } from "$/ui-models/uuid";
import { ApiBase } from "./api-base";

export class ProductInstantMessageActionsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "products", name: "ProductInstantMessageActionsApi" });
  }

  public async createInstantMessageAction(productId: uuid, instantMessageAction: PolicyActionInstantMessageMapped): Promise<PolicyActionInstantMessageMapped> {
    const res = await this.client.post<PolicyActionInstantMessageMapped>(`${productId.encodeURI()}/instant-message-actions`, instantMessageAction);
    return res.data;
  }

  public async updateInstantMessageAction(productId: uuid, instantMessageAction: PolicyActionInstantMessageMapped): Promise<PolicyActionInstantMessageMapped> {
    const res = await this.client.patch(`${productId.encodeURI()}/instant-message-actions/${instantMessageAction.id.encodeURI()}`, instantMessageAction);
    return res.data;
  }

  public async deleteInstantMessageAction(productId: uuid, instantMessageActionId: string): Promise<void> {
    await this.client.delete(`${productId.encodeURI()}/instant-message-actions/${instantMessageActionId.encodeURI()}`);
  }
}
