import { Dependency } from "../../detectors/handlers/utils/dependency-tree-parser/dependency";
import { LineRange } from "../../detectors/utils/package-line-number-finder";
import { GitBlameDetails } from "../../interfaces/ui-api/response/git-blame-details";
import { VulnerabilityReachability } from "../../reachability/reachability-entry";
import { GitResourceBase } from "../../report/interfaces/git-resource-base";
import { ReputationRiskResult } from "../../reputation/reputation-risk";
import { ReducingReason } from "../../risk-reduction/vulnerability-risk-reducer";
import { RiskSeverity } from "../../risk-severity";
import { LicenseDetails } from "../../sca/trivy-license-finding";
import { KEVCatalogResponse } from "./cisa-kev";
import { EPSSResponse } from "./epss-response";
import { ScoreCardResults } from "./scorecard";

export interface RawSBOMReports {
  trivyCycloneDxSBOM: TrivyCycloneDx.SBOM | null;
}

export type SBOMReportAvailability = Record<keyof RawSBOMReports, boolean>;

export interface SBOMReportResponse {
  findings: SBOMReportItemResponse[];
  dateGenerated: string;
}

export interface SBOMReportItemResponse extends GitResourceBase {
  search?: string;
  additionalInfo?: {
    sumVulnerabilities: SumVulnerabilities;
    numApplications: number;
    numPackages: number;
  };
  reportsAvailability?: SBOMReportAvailability;
}

export type SumVulnerabilities = Record<RiskSeverity, number> & { "*"?: number };

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TrivyCycloneDx {
  export interface PackageStatistics {
    downloads: number;
    period: "yearly" | "monthly" | "weekly" | "daily";
    date: string;
  }

  export interface PackageDescriptionAndLicense {
    description?: string;

    descriptionContentType?: "text/x-rst" | "text/markdown" | "text/plain" | "";
    licenses?: LicenseDetails[];

    repoURL?: string;
  }

  export type PackageDescriptionAndRawLicense = Omit<PackageDescriptionAndLicense, "licenses"> & {
    rawLicenses: string[];
  };

  //export type PackageType = "npm" | "pom" | "pip" | "pipenv" | "poetry" | "gomod" | "yarn" | "cargo" | "gradle" | "nuget" | "gemspec" | "bundler" | "composer" | "conan";

  export const PackageType = {
    npm: "npm",
    pom: "pom",
    pip: "pip",
    pipenv: "pipenv",
    poetry: "poetry",
    gomod: "gomod",
    yarn: "yarn",
    pnpm: "pnpm",
    cargo: "cargo",
    gradle: "gradle",
    nuget: "nuget",
    gemspec: "gemspec",
    bundler: "bundler",
    composer: "composer",
    conan: "conan",
    cocoapods: "cocoapods",
    // dart pubscpec, formal trivy package type
    pub: "pub",
    // NOTE: Not a formal trivy package type
    sbt: "sbt",
    "node-pkg": "node-pkg",
    "python-pkg": "python-pkg",
    // Elixir, Erlang
    hex: "hex"
  } as const;

  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace PackageType {
    export type npm = typeof PackageType.npm;
    export type pom = typeof PackageType.pom;
    export type pip = typeof PackageType.pip;
    export type pipenv = typeof PackageType.pipenv;
    export type poetry = typeof PackageType.poetry;
    export type gomod = typeof PackageType.gomod;
    export type yarn = typeof PackageType.yarn;
    export type pnpm = typeof PackageType.pnpm;
    export type cargo = typeof PackageType.cargo;
    export type gradle = typeof PackageType.gradle;
    export type nuget = typeof PackageType.nuget;
    export type gemspec = typeof PackageType.gemspec;
    export type bundler = typeof PackageType.bundler;
    export type composer = typeof PackageType.composer;
    export type conan = typeof PackageType.conan;
    export type cocoapods = typeof PackageType.cocoapods;
    export type sbt = typeof PackageType.sbt;
    export type pub = typeof PackageType.pub;
    export type hex = typeof PackageType.hex;
  }

  export type PackageType = (typeof PackageType)[keyof typeof PackageType];

  // The above allows:
  //
  // const f: PackageType = PackageType.pip;
  // const f1: PackageType = "pip";
  // const f2: PackageType.pip = PackageType.pip;
  // const f3: PackageType.conan = "conan";
  // const f4: PackageType = PackageType.npm;

  /**
   * In a trivy ref what follows the `pkg:` prefix
   * e.g. `pkg:npm/express@4.17.1`
   */
  export namespace RefSystem {
    export const gem = "gem";
    export const cargo = "cargo";
    export const cocoapods = "cocoapods";
    export const composer = "composer";
    export const conan = "conan";
    export const npm = "npm";
    export const maven = "maven";
    export const pypi = "pypi";
    export const golang = "golang";
    export const nuget = "nuget";
    export const swift = "swift";
    export const pub = "pub";
    // e.g: pkg:hex/ex_doc@0.34.2
    export const hex = "hex";
  }

  export type RefSystem = keyof typeof RefSystem;

  export interface Tool {
    vendor: string;
    name: string;
    version: string;
  }

  export interface Property {
    name: string;
    value: string;
  }

  export interface Component {
    "bom-ref": string;
    type: string;
    name: string;
    properties: Property[];
  }

  export interface Metadata {
    timestamp: string;
    tools: Tool[];
    component: Component;
  }

  export interface Property2 {
    name: string;
    value: string;
  }

  //TODO: break down to separate interfaces
  export interface LibraryOrApplication {
    "bom-ref": string;
    type: string;
    name: string;
    version?: string;
    purl?: string;
    properties: Property2[];
  }

  export interface LibraryWithGroup extends Library {
    group: string;
  }

  export const LibraryWithGroup = {
    is(value: LibraryOrApplication): value is LibraryWithGroup {
      return Library.is(value) && "group" in value;
    }
  };

  export const Library = {
    is(value: LibraryOrApplication): value is Library {
      return value.type === "library";
    }
  };

  export interface Library extends LibraryOrApplication {
    type: "library";
    /**
     * "built in" license information from trivy (if available)
     */
    licenses?: { license?: { name?: string } }[];
    /**
     * @deprecated use `packageTypes` instead
     */
    packageType?: PackageType;
    packageTypes?: PackageType[];
    scorecard?: ScoreCardResults;
    statistics?: PackageStatistics;
    /**
     * license information from arnica (based on deps dev, trivy, npm / maven / pip / etc)
     */
    descriptionAndLicense?: PackageDescriptionAndLicense;

    gitBlameDetailsByPath?: Record<string, GitBlameDetails>;
    lineRangesByPath?: Record<string, LineRange>;
    reputationRisk?: ReputationRiskResult;
    updatedAt?: string;

    version: string;
    purl: string;
    isDev?: boolean;
    //the lowest depth of this package (in case it exists in multiple places)
    depth?: number;

    //TODO: should this be here or a level above?
    /**
     * if this is a direct dependency, include the dependency tree for it (useful for license / reputation)
     */
    tree?: Dependency;

    releaseDate?: string;
    latestVersion?: string;
    latestVersionReleaseDate?: string;
  }

  export interface Application extends LibraryOrApplication {
    type: "application";
  }

  export interface TrivyDependency {
    ref: string;
    /**
     * This property is guaranteed to include a distinct list of all dependencies, either direct or transitive.
     *
     * Trivy used to store all dependencies flat (including direct and transitive dependencies) inside dependsOn
     * somewhere after trivy 0.37.2 this changed (at least for node) to only include direct dependencies, and the transitive dependencies where stored
     * as new Dependency nodes, each with their own dependsOn property, forming a dependency graph.
     */
    dependsOnFlat?: string[] | null;

    /**
     * @see https://cyclonedx.org/docs/1.4/json/#dependencies_items_dependsOn
     */
    dependsOn?: string[] | null;
  }

  export interface Source {
    name: string;
    url: string;
  }

  export interface RatingSource {
    name: string;
  }

  export interface Rating {
    source: RatingSource;
    severity: RiskSeverity;
    score?: number;
    method?: string;
    vector?: string;
  }

  export interface Advisory {
    url: string;
  }

  export interface Version {
    version: string;
    status: string;
  }

  export interface Affect {
    ref: string;
    versions: Version[];
  }

  export interface Vulnerability {
    id: string;
    source: Source;
    ratings: Rating[];
    description: string;
    recommendation?: string;
    advisories?: Advisory[];
    affects: Affect[];

    /**
     * CWE: Common Weakness Enumeration: e.g. https://cwe.mitre.org/data/definitions/74.html
     */
    cwes?: (string | number)[];
    published?: string;
    updated?: string;

    fixVersion?: string;

    kev?: KEVCatalogResponse["vulnerabilities"][number];
    epssScore?: EPSSResponse["data"][number];
    originalSeverity?: RiskSeverity;
    reducingReasons?: ReducingReason[];
    reachabilityConfidence?: number;
    reachabilityDetails?: VulnerabilityReachability[];
  }

  export interface SBOM {
    bomFormat: string;
    specVersion: string;
    serialNumber: string;
    version: number;
    metadata: Metadata;
    components?: LibraryOrApplication[];
    dependencies?: TrivyDependency[];
    vulnerabilities?: Vulnerability[];
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CheckovSBOM {
  //TODO: find out all package types, then remove `string`
  export type PackageType = "github_actions" | "openapi" | "cloudformation" | "terraform" | "dockerfile" | "circleci_pipelines" | "kubernetes" | string;

  export interface CheckovSBOMWrapper {
    bom: BOM;
  }

  export interface BOM {
    "@xmlns": string;
    "@version": string;
    "@serialNumber": string;
    metadata: Metadata;
    components: Components;
    dependencies: Dependencies;
    vulnerabilities: Vulnerabilities;
  }

  export interface Components {
    component: Component[];
  }

  export interface Component {
    "@type": Type;
    "@bom-ref": string;
    name: string;
    version: string;
    hashes: Hashes;
    purl: string;
  }

  export enum Type {
    Application = "application"
  }

  export interface Hashes {
    hash: Hash;
  }

  export interface Hash {
    "@alg": Alg;
    "#text": string;
  }

  export enum Alg {
    SHA1 = "SHA-1"
  }

  export interface Dependencies {
    dependency: Dependency[];
  }

  export interface Dependency {
    "@ref": string;
  }

  export interface Metadata {
    timestamp: string;
    tools: Tools;
  }

  export interface Tools {
    tool: Tool[];
  }

  export interface Tool {
    vendor: string;
    name: string;
    version: string;
    externalReferences: ExternalReferences;
  }

  export interface ExternalReferences {
    reference: Reference[];
  }

  export interface Reference {
    "@type": string;
    url: string;
  }

  export interface Vulnerabilities {
    vulnerability: Vulnerability[];
  }

  export interface Vulnerability {
    "@bom-ref": string;
    id: string;
    source: Source;
    ratings: Ratings;
    description: string;
    advisories: Advisories;
    affects: Affects;
  }

  export interface Advisories {
    advisory: Advisory;
  }

  export interface Advisory {
    url: string;
  }

  export interface Affects {
    target: Target;
  }

  export interface Target {
    ref: string;
  }

  export interface Ratings {
    rating: Rating;
  }

  export interface Rating {
    severity: RiskSeverity;
  }

  export interface Source {
    name: Name;
  }

  export enum Name {
    Checkov = "checkov"
  }
}
