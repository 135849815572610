import { uuid } from "$/ui-models/uuid";
import { ApiBase } from "./api-base";

export class ProductAssetsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "products", name: "ProductAssetsApi" });
  }

  public async addAssets(productId: uuid, assets: { locationIds?: uuid[]; repositoryIds?: uuid[] }): Promise<void> {
    await this.client.post(`/${productId.encodeURI()}/assets`, assets);
  }

  public async deleteRepositoryAsset(productId: uuid, repositoryId: uuid): Promise<void> {
    await this.client.delete(`/${productId.encodeURI()}/assets/repositories/${repositoryId.encodeURI()}`);
  }

  public async deleteLocationAsset(productId: uuid, locationId: uuid): Promise<void> {
    await this.client.delete(`/${productId.encodeURI()}/assets/locations/${locationId.encodeURI()}`);
  }

  public async setRepositoryBranches(productId: uuid, repositoryId: uuid, branches: string[]): Promise<void> {
    await this.client.patch(`${productId.encodeURI()}/assets/repositories/${repositoryId.encodeURI()}`, { branches });
  }

  public async setRepositoryPaths(productId: uuid, repositoryId: uuid, paths: string[]): Promise<void> {
    await this.client.patch(`${productId.encodeURI()}/assets/repositories/${repositoryId.encodeURI()}`, { paths });
  }
}
