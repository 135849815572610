import { UIFindingChampionDTO } from "../../../shared/src/ui-models/finding/ui-finding-champion";
import { ApiBase } from "./api-base";
import axios, { AxiosRequestConfig } from "axios";
import { FixVersionDto } from "$/detectors/handlers/utils/dependency-fix-recommender/fix-versions";
import { FindingFilter, FindingFilterWithCounts, FindingSort } from "$/dynamo/findingBase";
import { FindingsHistory } from "$/finding-types";
import { FindingFixPullRequest } from "$/findings/finding-fix-pull-requests";
import type { PaginatedResponse } from "$/interfaces/ui-api/response/paginated-response";
import { CodeRiskFinding, CodeRiskFindingPayload, CodeRiskFindingWithDismissReason } from "$/sast/code-risk-finding";
import type { HasSortKey } from "$/utility-types/has-sort-key";

const ENABLE_SEARCH_VERB = !!new URLSearchParams(window.location.search).get("searchVerb");

class CodeRiskApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "risk/code", name: "CodeRiskApi" });
  }

  public async deleteFinding(sortKey: string): Promise<void> {
    await this.client.delete(sortKey.encodeURI());
  }

  public async getCodeRiskReportWithPagination(
    page: number,
    pageSize: number,
    filter?: FindingFilter,
    sort?: FindingSort,
    search?: string,
    silent = false
  ): Promise<PaginatedResponse<CodeRiskFinding> | null> {
    const config: AxiosRequestConfig = {
      method: ENABLE_SEARCH_VERB ? "SEARCH" : "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };

    const res = await this.client.request<PaginatedResponse<CodeRiskFinding> | null>(config);
    return res.data ?? null;
  }

  public async getCodeRiskCounts(filter?: FindingFilter, search?: string, silent = false): Promise<number> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<number>("/counts", { filter, search }, config);
    return res.data ?? null;
  }

  public async getCodeRiskReportFilterCounts(
    filter?: FindingFilter,
    search?: string,
    column?: FindingFilterWithCounts,
    silent = false
  ): Promise<PartialRecord<keyof FindingFilter, Record<string, number>> | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<PartialRecord<keyof FindingFilter, Record<string, number>> | null>("/filter-counts", { filter, search, column }, config);
    return res.data ?? null;
  }

  public async getCodeRiskReportDetails<T extends CodeRiskFinding = CodeRiskFinding>(
    _dataType: CodeRiskFinding["dataType"],
    _scanner: CodeRiskFinding["scanner"],
    id: string
  ): Promise<NonNullable<HasSortKey<CodeRiskFindingPayload<T>>> | null> {
    try {
      const url = `${id.encodeURI()}/details`;
      const res = await this.client.get<HasSortKey<CodeRiskFindingPayload<T>> | null>(url);
      return res.data ?? null;
    } catch (e) {
      //TODO: add a config in axios base of ignore404: boolean to avoid repeating this / add a util method
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getCodeRiskReportHistory(id: string): Promise<FindingsHistory[] | null> {
    try {
      const url = `${id.encodeURI()}/history`;
      const res = await this.client.get<FindingsHistory[]>(url);
      return res.data ?? null;
    } catch (e) {
      //TODO: add a config in axios base of ignore404: boolean to avoid repeating this / add a util method
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getCodeRiskSingleFindingReport(sortKey: string, silent = false): Promise<CodeRiskFinding> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.get<CodeRiskFinding>(`${sortKey.encodeURI()}`, config);
    return res.data;
  }

  public async createSharableLink(sortKey: string, short: boolean): Promise<string> {
    const res = await this.client.post<string>(`${sortKey.encodeURI()}/link?short=${short}`);
    return res.data;
  }

  public async createFindingFixPr(sortKey: string, fixVersion: FixVersionDto): Promise<FindingFixPullRequest | null> {
    const res = await this.client.post<FindingFixPullRequest | null>(`${sortKey.encodeURI()}/pr`, fixVersion);
    return res.data;
  }

  public async getSimilarFindings(sortKey: string, offset: number, limit: number, sortBy: string, sortOrder: string): Promise<PaginatedResponse<CodeRiskFindingWithDismissReason>> {
    const similarFindingsUrl = `${sortKey.encodeURI()}/similar`;
    const res = await this.client.get<PaginatedResponse<CodeRiskFindingWithDismissReason>>(similarFindingsUrl, {
      params: {
        offset,
        limit,
        sortBy,
        sortOrder
      }
    });
    return res.data;
  }

  public async getChampionsForFinding(sortKey: string): Promise<UIFindingChampionDTO[]> {
    const res = await this.client.get<UIFindingChampionDTO[]>(`${sortKey.encodeURI()}/owners`);
    return res.data;
  }
}

export const CodeRisk = new CodeRiskApi();
