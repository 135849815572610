import { cloneDeep, isEqual } from "lodash";
import deepMerge from "./deep-merge";

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface ObjectConstructor {
    /**
     * Returns the names of the enumerable string properties and methods of an object.
     * @param o Object that contains the properties and methods. This can be an object that you created or an existing Document Object Model (DOM) object.
     */
    keys<T extends object>(o: T): (keyof T)[];

    /**
     * Create a clone of an object (using Lodash's {@link cloneDeep})
     * @param source the object to be cloned
     * @returns a deep clone of the source object
     */
    deepClone<T>(source: T): T;

    /**
     * Checks if two objects are deeply equal
     * @param a The first object
     * @param b The second object
     * @returns true if the objects are deeply equal, false otherwise
     */
    deepEquals<T>(a: T, b: T): boolean;

    /**
     * Checks the existence of an property on a given object and returns the object with the specified property marked as required.
     *
     * @template T - The type of the input object.
     * @template K - The type of the key to be marked as required and a string.
     * @param obj - The input object to check for the existence of the property.
     * @param prop - The name of the property to be marked as required.
     * @returns True if the property exists and not nullish, false otherwise.
     */
    hasProp<T, K extends keyof T & string>(obj: T, prop: K): obj is HasAttribute<T, K>;

    /**
     * Checks if the given key is a key of the given object
     * @param key The key to check
     * @param obj The object to check
     */
    keyOf<T extends object>(key: PropertyKey, obj: T): key is keyof T;

    /**
     * Extracts a nested property from an object in case it exists.
     */
    getNestedValue<T extends object, R = unknown>(obj: T, path: string): R;

    /**
     * Creates a copy of an object without the specified keys
     * @param obj The object to copy from
     * @param keys The keys to omit
     * @deprecated Use `omit` instead
     */
    copyWithout<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K>;

    /**
     * Hides all functions of an object.
     * @param obj The object to process
     * @note Makes all functions non-enumerable, non-configurable and non-writable
     */
    hideFunctions<T extends object>(obj: T): T;

    /**
     * Hides properties of an object.
     * @param obj The object to process
     * @param predicate A function that returns true for properties that should be hidden
     * @note Makes props non-enumerable, non-configurable and non-writable
     */
    hideProps<T extends object>(obj: T, predicate: (key: keyof T, value: T[keyof T]) => boolean): T;

    /**
     * Copies only the specified keys from an object
     * @param obj The object to copy from
     * @param keys The keys to copy
     */
    pick<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K>;

    /**
     * Copies an object except the specified keys
     * @param obj The object to copy from
     * @param keys The keys to omit copy
     */
    omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> & { [k in K]: never };

    /**
     * Deep merge two objects
     * @param left The left object
     * @param right The right object
     * @param clearOnNull If true, the left object will be unset if the right object is null (default is false)
     */
    deepMerge<T1, T2>(left: T1, right: T2 | undefined, clearOnNull?: boolean): T1 | T2 | (T1 & T2) | undefined;
  }
}

Object.deepClone ||= function deepClone<T>(source: T): T {
  /* TODO: audit all locations in the codebase where we use Object.deepClone with objects containing functions
     (i.e. with logs and/or a feature flag), refactor all locations to no longer do so, then switch to globalThis.structuredClone */
  return cloneDeep(source);
};

Object.deepEquals ||= function deepEquals<T>(a: T, b: T): boolean {
  return isEqual(a, b);
};

Object.keyOf ||= function keyOf<T extends object>(key: PropertyKey, obj: T): key is keyof T {
  return key in obj;
};

Object.hasProp ||= function hasProp<T, K extends keyof T & string>(obj: T, prop: K): obj is HasAttribute<T, K> {
  return !!obj && typeof obj === "object" && prop in obj && obj[prop] !== undefined && obj[prop] !== null;
};

Object.getNestedValue ||= function getNestedValue<T extends object, R = unknown>(obj: T, path: string): R {
  const keys = path.split(".");
  return keys.reduce((acc: any, key: string) => (typeof acc === "object" ? acc?.[key] : undefined), obj);
};

Object.omit ||= function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> & { [k in K]: never } {
  const entries = Object.entries(obj) as [keyof T, T[keyof T]][];
  const filteredEntries = entries.filter(([key]) => !keys.includes(key as K));
  const copy = Object.fromEntries(filteredEntries) as Omit<T, K> & { [k in K]: never };
  return copy;
};

Object.copyWithout ||= Object.omit;

Object.pick ||= function pick<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const entries = Object.entries(obj) as [keyof T, T[keyof T]][];
  const filteredEntries = entries.filter(([key]) => keys.includes(key as K));
  const copy = Object.fromEntries(filteredEntries) as Pick<T, K>;
  return copy;
};

Object.hideFunctions ||= function hideFunctions<T extends object>(obj: T): T {
  return Object.hideProps(obj, (key, value) => typeof value === "function");
};

Object.hideProps ||= function hideProps<T extends object>(obj: T, predicate: (key: keyof T, value: T[keyof T]) => boolean): T {
  const descriptors = Object.fromEntries(
    Object.entries(obj)
      .filter(([key, value]) => predicate(key as keyof T, value))
      .map(([key]) => [
        key,
        {
          enumerable: false,
          configurable: false,
          writable: false
        }
      ])
  );
  Object.defineProperties(obj, descriptors);
  return obj;
};

Object.deepMerge ||= deepMerge;
