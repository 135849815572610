<template>
  <v-container class="component dismiss-reason-confirmation pt-7" fluid>
    <v-card elevation="0">
      <v-card-text>
        <span>
          <span v-html-sanitize="description"></span>
          <b class="text-primary">{{ optionSelected }}</b>
        </span>
        <br />
        <br />
        Additional details:<br />
        <v-textarea rows="3" outlined v-model="reason" dense hide-details></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dismiss()"> {{ dismissButtonText }} </v-btn>
        <v-btn text @click="closeDialog()"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class DismissReasonConfirmation extends Vue {
  protected reason = "";

  @Prop({ type: String, required: true })
  protected optionSelected!: string;

  @Prop({ type: String, required: true })
  protected description!: string;

  @Prop({ type: String, required: false, default: "Dismiss" })
  protected dismissButtonText!: string;

  @Prop({ type: Function, required: true })
  protected resolve!: (result: string | null) => void;

  protected dismiss(): void {
    this.resolve(this.reason);
  }

  protected closeDialog(): void {
    this.resolve(null);
  }
}

export default DismissReasonConfirmation;
</script>
