type Comparable = string | number | boolean | Date;
type Indices<L extends number, T extends number[] = []> = T["length"] extends L ? T[number] : Indices<L, [T["length"], ...T]>;
type LengthAtLeast<T extends readonly unknown[], L extends number> = Pick<Required<T>, Indices<L>>;

interface ArrayReadingPolyfills<T> {
  /**
   * Creates a new array with all the elements of this array that are not null or undefined.
   * @note same as:
   * ```ts
   * array.filter((t): t is NonNullable<T> => t !== null && t !== undefined);
   * ```
   */
  nonNullable(): NonNullable<T>[];

  /**
   * Filters out null items from the array, e.g.
   *
   * instead of
   *
   * interface MyType {
   *   foo: string | null;
   *   bar: string | null;
   * }
   *
   * myTypeArray.filter((m): m is typeof m & {foo: string, bar: string} => !!m.foo && !!m.bar)
   *
   * you can use
   *
   * myTypeArray.nonNullableAttributes("foo", "bar")
   */
  nonNullableAttributes<K extends keyof NonNullable<T>>(...keys: K[]): NonNullable<NonNullable<T> & Record<K, NonNullable<NonNullable<T>[K]>>>[];

  /**
   * Filters an array asynchronously
   * @param predicate An async function that returns a boolean
   * @returns A promise that resolves to the filtered array
   */
  filterAsync(predicate: (item: T) => Promise<boolean>): Promise<T[]>;

  /**
   * Filters an array asynchronously
   * @param chunkSize The maximum number of items to process in parallel
   * @param predicate An async function that returns a boolean
   * @returns A promise that resolves to the filtered array
   */
  chunkedFilterAsync(chunkSize: number, predicate: (item: T) => Promise<boolean>): Promise<T[]>;

  /**
   * Checks if every element in the array satisfies the provided testing function asynchronously.
   * @param predicate An async function that returns a boolean
   * @returns A promise that resolves to a boolean
   */
  everyAsync(predicate: (item: T) => Promise<boolean> | boolean): Promise<boolean>;

  /**
   * Checks if at least one element in the array satisfies the provided testing function asynchronously.
   * @param predicate An async function that returns a boolean
   * @returns A promise that resolves to true if the callback function returns a truthy value for any array element; otherwise, false.
   */
  someAsync(predicate: (item: T) => boolean | Promise<boolean>): Promise<boolean>;

  /**
   * Calls a defined asynchronous callback function on each element of an array, and returns an array that contains the results.
   * @param callback An async function that accepts up to three arguments. The map method calls the callback function one time for each element in the array.
   * @param thisArg An object to which the "this" keyword can refer in the callback function. If thisArg is omitted, undefined is used as the "this" value.
   * @returns A promise that resolves to the mapped array
   */
  mapAsync<U>(callback: (value: T, index: number, array: T[]) => Promise<U>, thisArg?: unknown): Promise<U[]>;

  /**
   * Calls a defined asynchronous callback function on each element of an array, and returns an array that contains the results.
   * @param chunkSize The maximum number of items to process in parallel
   * @param callback An async function that accepts up to three arguments. The map method calls the callback function one time for each element in the array.
   * @param thisArg An object to which the "this" keyword can refer in the callback function. If thisArg is omitted, undefined is used as the "this" value.
   * @returns A promise that resolves to the mapped array
   */
  chunkedMapAsync<U>(chunkSize: number, callback: (value: T, index: number, array: T[]) => Promise<U>, thisArg?: unknown): Promise<U[]>;

  /**
   * Calls a defined asynchronous callback function on each element of an array, and returns an array that contains the flattened results.
   * Similar to `flatMap` but the callback function is async.
   * @param callback An async function that accepts up to three arguments. The flatMapAsync method calls the callback function one time for each element in the array.
   * @param thisArg An object to which the "this" keyword can refer in the callback function. If thisArg is omitted, undefined is used as the "this" value.
   * @returns A promise that resolves to the flattened mapped array
   */
  flatMapAsync<U>(callback: (value: T, index: number, array: T[]) => Promise<U[]>, thisArg?: object): Promise<U[]>;

  /**
   * Calls a defined asynchronous callback function on each element of an array, and returns an array that contains the flattened results.
   * Similar to `flatMap` but the callback function is async.
   * @param chunkSize The maximum number of items to process in parallel
   * @param callback An async function that accepts up to three arguments. The flatMapAsync method calls the callback function one time for each element in the array.
   * @param thisArg An object to which the "this" keyword can refer in the callback function. If thisArg is omitted, undefined is used as the "this" value.
   * @returns A promise that resolves to the flattened mapped array
   */
  chunkedFlatMapAsync<U>(chunkSize: number, callback: (value: T, index: number, array: T[]) => Promise<U[]>, thisArg?: object): Promise<U[]>;

  /**
   * Iterates an array asynchronously
   * @param callback An async function to call for each item in the array
   * @returns A promise that resolves the iteration
   */
  forEachAsync(callback: (item: T, index: number, array: T[]) => Promise<unknown>): Promise<void>;

  /**
   * Iterates an array asynchronously in chunks
   * @param chunkSize The maximum number of items to process in parallel
   * @param callback An async function to call for each item in the array
   * @returns A promise that resolves the iteration
   */
  chunkedForEachAsync(chunkSize: number, callback: (value: T, index: number, array: T[]) => Promise<void>): Promise<void>;

  /**
   * uses `.sort()` to sort a copy of the array.
   * @param comparer A comparer delegate that returns the value to sort by
   * @param [order] Optional. Defines the sort order. Defaults to `Direction.ascending`
   */
  orderBy(comparer: (item: T) => Comparable, order?: Direction): Array<T>;

  /**
   * uses `.sort()` to sort a copy of the array.
   * @param comparer A comparer key that to sort by
   * @param [order] Optional. Defines the sort order. Defaults to `Direction.ascending`
   */
  orderBy<C extends keyof PickPropertiesImplementing<T, Comparable>>(comparer: C, order?: Direction): Array<T>;

  /**
   * Returns a new array with the maximum specified number of items from the start of the array.
   * @param n The number of items to take
   */
  take(n: number): T[];

  //https://stackoverflow.com/questions/61740599/rangeerror-maximum-call-stack-size-exceeded-with-array-push
  pushAll(items: T[]): void;

  toSet(): Set<T>;

  /**
   * Returns a map with the frequency of each item in the array
   */
  frequencyMap(): Map<T, number>;

  /**
   * Returns the last element of the array or undefined if the array is empty.
   */
  last(): T | undefined;

  /**
   * Determines whether an array includes all the provided items, returning true or false as appropriate.
   * @param elements The items to search for. If `elements` is empty, this returns true.
   */
  includesAll(elements: readonly T[]): boolean;

  /**
   * Skips items in the array while the predicate returns true.
   */
  skipWhile(predicate: (item: T) => boolean): T[];

  /**
   * Takes items in the array while the predicate returns true.
   */
  takeWhile(predicate: (item: T) => boolean): T[];

  /**
   * Omit the specified items from the array.
   * @param items The items to omit.
   */
  omit(items: readonly T[]): T[];
}

// interface ReadonlyArray<T> extends ArrayReadingPolyfills<T> {}

interface Array<T> extends ArrayReadingPolyfills<T> {
  /**
   * Adds a unique item to an array using the push method.
   * @param {T} item - The item to add to the array.
   * @returns {void}
   * @template T
   */
  pushUnique<T>(item: T): void;

  /**
   * Inserts a separator between each item in the array.
   * @param separator The separator to insert between each item.
   */
  insertBetween<S>(separator: S): (T | S)[];

  /**
   * Appends the specified items to the end of the array.
   * @param items Items to append to the array
   */
  append<S>(...items: S[]): (T | S)[];
}

interface ArrayConstructor {
  /**
   * Guarantees that the result is an array. If it's not an array, it's wrapped in an array.
   * @param arg
   */
  guarantee<T>(arg: T[] | T | undefined): NonNullable<T>[];

  fromAsync<T, O = T>(asyncItems: AsyncIterable<T> | Iterable<T> | ArrayLike<T>, mapFn?: (value: T, index: number) => O, thisArg?: object): Promise<Array<O>>;

  /**
   * Making sure that the array has at least the specified length
   * @param arr The array to check
   * @param len The length to check
   */
  hasLengthAtLeast<T, L extends number>(arr: T[], len: L): arr is T[] & LengthAtLeast<T[], L>;

  hasSameElements<T>(arr1: T[], arr2: T[]): boolean;

  /**
   * Creates a new array with the specified range of numbers.
   * @param start The start of the range (inclusive)
   * @param end The end of the range (inclusive)
   */
  range(start: number, end: number): number[];
}

enum Direction {
  ascending = 1,
  descending = -1
}

(globalThis as typeof globalThis & { Direction: typeof Direction }).Direction = Direction;

Array.prototype.nonNullable ||= function nonNullable<T>(this: T[]): NonNullable<T>[] {
  return this.filter((t): t is NonNullable<T> => t !== null && t !== undefined);
};

Array.prototype.nonNullableAttributes ||= function nonNullableAttributes<T, K extends keyof NonNullable<T>>(
  this: T[],
  ...keys: K[]
): NonNullable<(NonNullable<T> & Record<K, NonNullable<NonNullable<T>[K]>>)[]> {
  return this.filter((item): item is T & Record<K, NonNullable<T[keyof T]>> => {
    if (!item) {
      return false;
    }
    for (const key of keys) {
      if (item[key] === null || item[key] === undefined) {
        return false;
      }
    }
    return true;
  });
};
/**
 * avoids Maximum call stack size exceeded with array.push(...)
 * @see //https://stackoverflow.com/questions/61740599/rangeerror-maximum-call-stack-size-exceeded-with-array-push
 * @param items
 */
Array.prototype.pushAll ||= function pushAll<T>(this: T[], items: T[]): void {
  for (const item of items) {
    this.push(item);
  }
};

Array.prototype.filterAsync ||= async function filterAsync<T>(this: T[], predicate: (item: T) => Promise<boolean>): Promise<T[]> {
  const results = await Promise.all(this.map(predicate));
  return this.filter((_, index) => results[index]);
};

Array.prototype.chunkedFilterAsync ||= async function chunkedFilterAsync<T>(this: T[], chunkSize: number, predicate: (item: T) => Promise<boolean>): Promise<T[]> {
  const chunks = this.chunk(chunkSize);
  const results: T[] = [];
  for (const chunk of chunks) {
    const chunkResults = await Promise.all(chunk.map(predicate));
    results.pushAll(chunk.filter((_, index) => chunkResults[index]));
  }
  return results;
};

Array.prototype.everyAsync ||= async function everyAsync<T>(this: T[], predicate: (item: T) => Promise<boolean> | boolean): Promise<boolean> {
  // Returns "false" if any of the results is "false", otherwise "undefined" if all results are "true"
  const result = await Promise.raceTo(this.map(predicate), (i) => !i);
  return result === undefined;
};

Array.prototype.someAsync ||= async function someAsync<T>(this: T[], predicate: (item: T) => Promise<boolean>): Promise<boolean> {
  const result = await Promise.raceTo(this.map(predicate));
  return !!result;
};

Array.prototype.forEachAsync ||= async function forEachAsync<T>(this: T[], callbackFn: (item: T, index: number, array: T[]) => Promise<void>): Promise<void> {
  await Promise.all(this.map(callbackFn));
};

Array.prototype.chunkedForEachAsync ||= async function chunkedForEachAsync<T>(
  this: T[],
  chunkSize: number,
  callbackFn: (item: T, index: number, array: T[]) => Promise<void>
): Promise<void> {
  const chunks = this.chunk(chunkSize);
  for (const chunk of chunks) {
    await chunk.forEachAsync(callbackFn);
  }
};

Array.prototype.mapAsync ||= async function mapAsync<T, U>(this: T[], callback: (value: T, index: number, array: T[]) => Promise<U>, thisArg?: object): Promise<U[]> {
  return Promise.all(this.map(callback, thisArg));
};

Array.prototype.chunkedMapAsync ||= async function chunkedMapAsync<T, U>(
  this: T[],
  chunkSize: number,
  callback: (value: T, index: number, array: T[]) => Promise<U>,
  thisArg?: unknown
): Promise<U[]> {
  const results: U[] = [];
  const chunks = this.chunk(chunkSize);
  for (const chunk of chunks) {
    results.pushAll(await chunk.mapAsync(callback, thisArg));
  }
  return results;
};

Array.prototype.flatMapAsync ||= async function mapAsync<T, U>(this: T[], callback: (value: T, index: number, array: T[]) => Promise<U[]>, thisArg?: object): Promise<U[]> {
  const results = await this.mapAsync(callback, thisArg);
  return results.flat();
};

Array.prototype.chunkedFlatMapAsync ||= async function chunkedFlatMapAsync<T, U>(
  this: T[],
  chunkSize: number,
  callback: (value: T, index: number, array: T[]) => Promise<U[]>,
  thisArg?: unknown
): Promise<U[]> {
  const results = await this.chunkedMapAsync(chunkSize, callback, thisArg);
  return results.flat();
};

Array.prototype.pushUnique ||= function pushUnique<T>(this: T[], value: T): void {
  if (!this.includes(value)) {
    this.push(value);
  }
};

Array.prototype.orderBy ||= function orderBy<T, C extends keyof PickPropertiesImplementing<T, Comparable>>(
  this: T[],
  comparer: C | ((item: T) => Comparable),
  order: Direction = Direction.ascending
): Array<T> {
  const extractor: (item: T) => Comparable = typeof comparer === "function" ? comparer : (item: T) => item[comparer] as Comparable;
  const arr = Array.from(this);

  return arr.sort((a: T, b: T) => {
    const aValue = extractor(a);
    const bValue = extractor(b);
    if (aValue < bValue) {
      return -1 * order;
    }
    if (aValue > bValue) {
      return 1 * order;
    }
    return 0;
  });
};

Array.prototype.insertBetween ||= function insertBetween<T, S>(this: (T | S)[], separator: S): (T | S)[] {
  for (let i = this.length - 1; i > 0; i--) {
    this.splice(i, 0, separator);
  }
  return this;
};

Array.prototype.append ||= function append<T, S>(this: (T | S)[], ...items: S[]): (T | S)[] {
  this.push(...items);
  return this;
};

Array.prototype.take ||= function take<T>(this: T[], n: number): T[] {
  return this.slice(0, n === Infinity ? undefined : n);
};

Array.guarantee ||= <T>(arg: T[] | T | undefined): NonNullable<T>[] => {
  if (!arg) {
    return [];
  }
  if (Array.isArray(arg)) {
    return arg.nonNullable();
  }
  return [arg];
};

Array.fromAsync ||= async <T, O = T>(asyncItems: AsyncIterable<T> | Iterable<T> | ArrayLike<T>, mapFn?: (value: T, index: number) => O, thisArg?: object): Promise<Array<O>> => {
  // Adapted from https://github.com/es-shims/array-from-async/blob/main/index.mjs
  if (Symbol.asyncIterator in asyncItems || Symbol.iterator in asyncItems) {
    const result: O[] = [];
    let i = 0;
    for await (const v of asyncItems) {
      if (mapFn) {
        result.push(await mapFn.call(thisArg, v, i));
      } else {
        result.push(v as O);
      }
      i++;
    }
    return result;
  }

  // In this case, the items are assumed to be an ArrayLike object
  const result: O[] = [];
  for (let i = 0; i < asyncItems.length; i++) {
    const v = await asyncItems[i];
    if (mapFn) {
      result[i] = await mapFn.call(thisArg, v as T, i);
    } else {
      result[i] = v as O;
    }
  }
  return result;
};

Array.hasLengthAtLeast ||= function hasLengthAtLeast<T, L extends number>(arr: T[], len: L): arr is T[] & LengthAtLeast<T[], L> {
  return arr.length >= len;
};

Array.hasSameElements ||= function <T>(arr1: T[], arr2: T[]): boolean {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  const array1Elements = new Set(arr1);
  return arr2.every((element) => array1Elements.has(element));
};

Array.prototype.toSet ||= function toSet<T>(this: T[]): Set<T> {
  return new Set(this);
};

Array.prototype.frequencyMap ||= function frequencyMap<T>(this: T[]): Map<T, number> {
  const frequencyMap = new Map<T, number>();
  for (const item of this) {
    frequencyMap.setOrUpdate(
      item,
      () => 1,
      (_, count) => count + 1
    );
  }
  return frequencyMap;
};

Array.range ||= function range(start: number, end: number): number[] {
  const dir = end < start ? -1 : 1;
  const diff = (end - start) * dir;
  return Array.from({ length: diff + 1 }, (_, i) => start + dir * i);
};

Array.prototype.last ||= function last<T>(this: T[]): T | undefined {
  return this[this.length - 1];
};

Array.prototype.includesAll ||= function includesAll<T>(this: T[], elements: T[]): boolean {
  const arraySet = this.toSet();

  for (const elem of elements) {
    if (!arraySet.has(elem)) {
      return false;
    }
  }

  return true;
};

Array.prototype.skipWhile ||= function skipWhile<T>(this: T[], predicate: (item: T) => boolean): T[] {
  function* generate(array: T[]) {
    const iterator = array.values();
    let current = iterator.next();
    while (!current.done && predicate(current.value)) {
      current = iterator.next();
    }
    while (!current.done) {
      yield current.value;
      current = iterator.next();
    }
  }
  return Array.from(generate(this));
};

Array.prototype.takeWhile ||= function <T>(this: T[], predicate: (item: T) => boolean): T[] {
  function* generate(array: T[]) {
    const iterator = array.values();
    let current = iterator.next();
    while (!current.done && predicate(current.value)) {
      yield current.value;
      current = iterator.next();
    }
  }
  return Array.from(generate(this));
};

Array.prototype.omit ||= function <T>(this: readonly T[], items: T[]): T[] {
  const itemsSet = new Set(items);
  return this.filter((item) => !itemsSet.has(item));
};
