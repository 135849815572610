import Vue, { VueConstructor } from "vue";
import { InputValidationRule } from "vuetify";
import type { DataTableHeader } from "vuetify";
import { Deferred } from "$/utility-types";
import CheckboxDialogContent from "@/components/layout/checkbox-dialog-content.vue";
import PromptDialogContent from "@/components/layout/prompt-dialog-content.vue";
import SelectDialogContent from "@/components/layout/select-dialog-content.vue";
import VideoDialogContent from "@/components/layout/video-dialog-content.vue";

interface DialogAction<T> {
  name: string;
  value?: T;
  color?: string;
  default?: boolean;
  submit?: boolean;
}

interface DialogConfig<TResult = unknown, TBind extends Record<string, unknown> = Record<string, unknown>> {
  content: VueConstructor | string | HtmlContent;
  bindData?: TBind | null;
  title?: string;
  actions?: DialogAction<TResult>[];
  deferred: Deferred<TResult>;
  persistent?: boolean;
  fullscreen?: boolean;
}

export interface SelectOption<T> {
  title: string;
  value: T;
  description?: string;
  icon?: string | undefined;
  iconRight?: string | undefined;
  initialChecked?: boolean;
  avatar?: string | undefined | null;
  disabled?: boolean;
}

export class HtmlContent {
  public constructor(public readonly html: string) {}
}

export const Dialog = new (class DialogState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _config: DialogConfig<any> | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public get config(): DialogConfig | null {
    return this._config;
  }

  public async open<T>(config: Omit<DialogConfig<T>, "deferred">): Promise<T> {
    this._config = {
      ...config,
      deferred: new Deferred<T>()
    };
    this._config.deferred.promise.finally(() => (this._config = null));
    return this._config.deferred.promise;
  }

  public dismiss() {
    this._config = null;
  }

  public async confirm(title?: string, content?: VueConstructor | string | HtmlContent, bindData?: Record<string, unknown>): Promise<boolean> {
    return await this.open({
      content: content ?? "",
      actions: [
        { name: "OK", value: true, color: "success", default: true },
        { name: "Cancel", value: false, color: "" }
      ],
      title: title ?? "Are you sure?",
      bindData: bindData || null
    });
  }

  public async prompt(title?: string, defaultValue?: string, hint?: string, rules?: InputValidationRule[]): Promise<string | null> {
    const args = {
      value: defaultValue ?? null
    };
    const result = await this.open({
      title: title ?? "",
      actions: [
        { name: "OK", color: "success", value: args, submit: true },
        { name: "Cancel", color: "", value: null }
      ],
      content: PromptDialogContent,
      bindData: {
        args,
        hint,
        rules
      }
    });
    return result?.value ?? null;
  }

  /**
   * Prompts the user to select an option from a list
   * @param options The options to select from.
   * @param [title] Optional. The title of the dialog. defaults is no title.
   * @param [defaultValue] Optional. The default value to pre-select. default is none.
   * @param [sorter] Optional. Either a sorting function or `true` (denotes options are pre-sorted). default (`undefined`) will sort by title.
   * @returns The selected value or `null` if cancelled.
   */
  public async select<T>(options: SelectOption<T>[], title?: string, defaultValue?: T, sorter?: true | ((a: SelectOption<T>, b: SelectOption<T>) => number)): Promise<T | null> {
    const args = {
      value: defaultValue ?? null
    };
    const result = await this.open({
      title: title ?? "",
      actions: [
        { name: "OK", color: "success", value: args, submit: true },
        { name: "Cancel", color: "", value: null }
      ],
      content: SelectDialogContent,
      bindData: {
        args,
        options,
        sorter: sorter === true ? undefined : sorter,
        preSorted: sorter === true
      }
    });
    return result?.value ?? null;
  }

  public async checkbox<T>(options: T[], headers: DataTableHeader[], title?: string, subTitle?: string, defaultValue?: T[], sorter?: (a: T, b: T) => number): Promise<T[] | null> {
    const args = {
      value: defaultValue ?? []
    };
    const result = await this.open({
      title: title ?? "",
      actions: [
        { name: "OK", color: "success", value: args, submit: true },
        { name: "Cancel", color: "", value: null }
      ],
      content: CheckboxDialogContent,
      bindData: {
        args,
        options,
        sorter,
        headers,
        subTitle
      }
    });

    const res = result?.value as T[];
    return res ?? null;
  }

  public async video(src: string, title?: string): Promise<void> {
    await this.open({
      title: title ?? "",
      actions: [{ name: "Close", color: "", value: false }],
      content: VideoDialogContent,
      persistent: true,
      fullscreen: true,
      bindData: {
        src,
        title
      }
    });
  }
})();
