import { Error as ErrorState } from "./error-state";
import { Events } from "./events-state";
import { Preferences } from "./preferences-state";
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenSilentlyVerboseResponse,
  RedirectLoginOptions
} from "@auth0/auth0-spa-js";
import type * as FullStory from "@fullstory/browser";
import Vue from "vue";
import { Role } from "$/dynamo";
import { Tier } from "$/interfaces/ui-api/billing-tiering/tier";
import { Admin, Auth as AuthApi, Users } from "@/api";
import { AxiosUserException } from "@/errors/axios-user-exception";
import { CALLBACK_MESSAGE_KEY, ICallbackMessage } from "@/interfaces/callback-message";
import { STATUS_CHANGE_SCOPES, Scope } from "@/interfaces/scope";
import { User } from "@/models/user";
import { router } from "@/plugins";
import { AnalyticsService } from "@/services/analytics-service";
import { CallbackHandlers } from "@/services/auth/callback-handlers";
import { Auth0CallbackHandler } from "@/services/auth/callback-handlers/auth0-callback-handler";
import { Auth0Connection, Env } from "@/state/environment-state";
import { SessionStorageItem } from "@/utils/local-storage-item";

declare global {
  interface Window {
    tier?: Tier; // TODO: remove;
    FS: typeof FullStory;
  }
}
//oauth2 / integration callback constants
export const CALLBACK_PATH_GITHUB = "/callback/github";
export const CALLBACK_PATH_ADO = "/callback/ado";

const Auth0ClientCustomDomainStorageKey = "auth0ClientCustomDomain";
// GitHub and SSO don't support multiple allowed callback urls, but google and AzureAD do.
const Auth0ClientCustomDomainConnections: Auth0Connection[] = ["google-oauth2", "AzureAD"];
const Auth0LastUsedConnectionKey = "auth0LastUsedConnection";
export const Auth = new (class AuthState {
  /**
   * Session token also used for Magic Links(tm)
   * @private
   */
  private readonly sessionToken = new SessionStorageItem<string>("signedToken", null);
  private _user: User | null = null;
  private _signedToken: string | null = null;
  private _selectedOrgId: string | null = null;
  private initialized = false;
  public loading = true;
  public redirectMessage: string[] | null = null;
  public _auth0ClientOldDomain?: Auth0Client;
  public _auth0ClientCustomDomain?: Auth0Client;

  public get auth0Client(): Auth0Client | undefined {
    if (this.useCustomDomain) {
      return this._auth0ClientCustomDomain;
    }
    return this._auth0ClientOldDomain;
  }

  public set useCustomDomain(value: boolean) {
    if (value) {
      localStorage.setItem(Auth0ClientCustomDomainStorageKey, "true");
    } else {
      localStorage.removeItem(Auth0ClientCustomDomainStorageKey);
    }
  }

  public get useCustomDomain(): boolean {
    return localStorage.getItem(Auth0ClientCustomDomainStorageKey) === "true";
  }

  public set lastUsedConnection(value: { connection?: string; provider: string } | undefined) {
    if (value) {
      localStorage.setItem(Auth0LastUsedConnectionKey, JSON.stringify(value));
    } else {
      localStorage.removeItem(Auth0LastUsedConnectionKey);
    }
  }

  public get lastUsedConnection(): { connection?: string; provider: string } | undefined {
    const value = localStorage.getItem(Auth0LastUsedConnectionKey);
    if (value) {
      return JSON.parse(value);
    }
    return undefined;
  }

  /**
   * The signed token used for magic link authentication
   */
  public get signedToken(): string | null {
    return this._signedToken;
  }

  public popupOpen = false;
  public error?: Error;

  public constructor() {
    Vue.observable(this);
  }

  public async getSelectedOrgId(): Promise<string | null> {
    if (this.isArnicaAdmin) {
      this._selectedOrgId = await Admin.getTenantOverride();
      return this._selectedOrgId;
    }
    return null;
  }

  public async setSelectedOrgId(tenant: string | null) {
    if (this.isArnicaAdmin && tenant !== this._selectedOrgId) {
      this._selectedOrgId = tenant;
      await Admin.setTenantOverride(tenant);
      location.reload();
    }
  }

  public async init() {
    if (this.initialized) {
      return;
    }
    try {
      if (await this.signedTokenBasedAuthentication()) {
        return;
      }

      // Create a new instance of the SDK client using members of the given options object
      const options: Auth0ClientOptions = {
        ...(await Env.getAuth0Config()),
        redirect_uri: `${window.location.origin}${Auth0CallbackHandler.path}`,
        cacheLocation: "localstorage" // can't use localStorage for security reasons
      };

      this._auth0ClientOldDomain = await createAuth0Client(options);
      this._auth0ClientCustomDomain = await createAuth0Client({ ...options, domain: await Env.getAuth0CustomDomain() });

      await this.setUserInternal();

      await AuthState.handleCallbacks();

      if (!this._user) {
        // not authenticated?
        return;
      }

      if (!this._user.isEmailVerified) {
        this.initialized = true;
        return;
      }

      await this.afterEmailIsVerified();
    } catch (e) {
      // user is not authenticated, and we have an IdP in the request
      AnalyticsService.logEvent("Error in Auth Init", undefined, { group: "General" });
      if (e instanceof Error) {
        this.error = e;
      }

      const uiException = AxiosUserException.tryCreate(e);
      if (uiException) {
        await Auth.auth0SignOut();
        await router.push({ name: "login", params: { error: uiException.message } });
        throw uiException;
      }

      await ErrorState.show(e);
      throw e;
    } finally {
      // why do we need both?
      this.initialized = true;
      this.loading = false;
    }
  }

  private async triggerStoredCallback() {
    try {
      const callbackMessage = window.sessionStorage.getItem(CALLBACK_MESSAGE_KEY);
      if (!callbackMessage) {
        return;
      }

      window.sessionStorage.removeItem(CALLBACK_MESSAGE_KEY);
      const callback = JSON.parse(callbackMessage) as ICallbackMessage;
      await Vue.nextTick();
      window.location.href = callback.redirect;
    } catch (e) {
      console.warn(e);
    }
  }

  private static async handleCallbacks() {
    const query = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    const callbackHandler = CallbackHandlers.resolve(path);
    if (callbackHandler) {
      await callbackHandler.handle(query);
    }
  }

  public get isNonDefaultOrgId(): boolean {
    return !!this._selectedOrgId;
  }

  public get user(): User | null {
    return this._user;
  }

  public get scopes(): PartialRecord<Scope, true | undefined> {
    return this.user?.scopes || {};
  }

  public get trueScopes(): Scope[] {
    return Object.keys(this.scopes).filter((key) => this.scopes[key]) as Scope[];
  }

  public get isOrgOwner(): boolean {
    return this.user?.userInfo?.role === Role.owner;
  }

  public get authenticated(): boolean {
    return !!this.user;
  }

  public get isEmailVerified(): boolean {
    return !!this._user?.isEmailVerified;
  }

  public get authenticatedAndEmailVerified(): boolean {
    return this.authenticated && this.isEmailVerified;
  }

  public get activated(): boolean {
    return this.user?.userInfo?.requiresActivation !== true;
  }

  public get hasOrgId(): boolean {
    return !!this.user?.userInfo?.orgId;
  }

  public get isArnicaAdmin(): boolean {
    return !!this.scopes.arnica_admin;
  }

  public async reloadUser() {
    await this.getTokenSilently({ ignoreCache: true });
    await this.setUserInternal(true);
  }

  public async setUserInternal(forceReload = false) {
    if (!this._user || forceReload) {
      const auth0User = await this.auth0Client?.getUser({});
      if (auth0User) {
        const authUserInfo = await AuthApi.getUserInfo();
        this._user = new User(auth0User, authUserInfo?.userInfo);
      }
    }
  }

  public async signOut() {
    AnalyticsService.logEvent("User Logged Out", undefined, { group: "General" });
    this.sessionToken.item = null;
    await this.auth0SignOut();
  }

  public async auth0SignOut() {
    await this.auth0Client?.logout({ localOnly: true, federated: false });
    //make sure we clear the token even if we don't have an auth0Client
    this.clearAuth0Token();
    this._user = null;
    this.useCustomDomain = false;
    await router.replace({ name: "login", ignoreRedirectError: true });
  }

  private clearAuth0Token() {
    try {
      for (const key of Object.keys(localStorage)) {
        if (typeof key === "string" && key.startsWith("@@auth0spajs@@")) {
          localStorage.removeItem(key);
        }
      }
    } catch (e) {
      console.error("Unable to clear auth0 token", e);
    }
  }

  /** Authenticates the user using the redirect method */
  public async loginWithRedirect(o: RedirectLoginOptions) {
    this.useCustomDomain = Auth0ClientCustomDomainConnections.includes(o.connection as Auth0Connection);
    return this.auth0Client?.loginWithRedirect({ ...o, prompt: "login", scope: "openid groups email" });
  }

  // /** Returns all the claims present in the ID token */
  // private async getIdTokenClaims(o?: GetIdTokenClaimsOptions): Promise<IdToken | undefined> {
  //   this.auth0Client?.isAuthenticated();
  //   return this.auth0Client?.getIdTokenClaims(o);
  // }

  /** Returns all the claims present in the ID token */
  public async getIdToken(o?: GetIdTokenClaimsOptions): Promise<string | undefined> {
    return this._signedToken || (await this.getTokenSilently(o))?.id_token;
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  private async getTokenSilently(o?: GetTokenSilentlyOptions): Promise<GetTokenSilentlyVerboseResponse | undefined> {
    try {
      // if(Math.random() > 0.95){
      //   throw { error:"login_required" };
      // }
      return await this.auth0Client?.getTokenSilently({ ...o, detailedResponse: true });
    } catch (e) {
      if (e && typeof e === "object" && "error" in e && e.error === "login_required") {
        this._user = null;
        await router.push({ name: "login", query: { reason: "expired" } });
        return;
      }
      throw e;
    }
  }

  // /** Gets the access token using a popup window */
  // getTokenWithPopup(o: GetTokenWithPopupOptions) {
  //   return this.auth0Client?.getTokenWithPopup(o);
  // }

  /**
   * All steps to be performed after the user's email is verified
   */
  public async afterEmailIsVerified() {
    if (!this._user) {
      throw new Error("User is not authenticated");
    }

    await Events.emit("auth.initialized", this);

    // this requires email to be verified
    this._user.userInfo = await Users.me(true, true);

    AnalyticsService.init(this._user);

    await this.getSelectedOrgId();

    void Preferences.load(this._user.userInfo);

    this.storeProvider(this._user.userInfo.username);
    await this.triggerStoredCallback();
  }

  private storeProvider(username: string) {
    const [providerName, connectionName] = username.split("|") || [];
    //all other provider names are the same as the one on the username, except for waad which is AzureAD
    //(the other 3 are github, google-oauth2, and samlp)
    //only samlp has a connection name (which is the tenant ID by convention)
    const provider = providerName === "waad" ? "AzureAD" : providerName;
    const connection = providerName === "samlp" ? connectionName : undefined;
    if (!provider) {
      console.warn("No provider found in username", username);
      this.lastUsedConnection = undefined;
      return;
    }
    this.lastUsedConnection = { provider, connection };
  }

  private async signedTokenBasedAuthentication(): Promise<boolean> {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("t")?.trim() || this.sessionToken.item || null;
    if (!token) {
      return false;
    }
    this._signedToken = `Token ${token}`;
    url.searchParams.delete("t");
    this.sessionToken.item = token;
    window.history.replaceState({}, "", url.toString());

    this._user = await AuthApi.getUserInfo();

    return true;
  }

  public hasPermissionToChangeStatus(): boolean {
    const scopes = this.scopes;
    return STATUS_CHANGE_SCOPES.some((scope) => scopes[scope]);
  }
})();
