export interface UIException {
  /** Error message */
  message: string;

  /** HTTP status text */
  error: string;

  /** HTTP status code */
  statusCode: number;

  /** Unique error locator */
  id: string;

  /** Signifies this is a user facing error */
  ui: true;
}

export const UIException = {
  is(error: unknown): error is UIException {
    if (typeof error !== "object" || error === null) {
      return false;
    }

    if (!("ui" in error && "id" in error)) {
      return false;
    }

    return true;
  }
} as const;
