import { PolicyItemType } from "../../policy-item-base";
import { PolicyAction, PolicyActionSubType } from "../policy-action";
import { LlmIntegrationType } from "../../../../dynamo";
declare module "../policy-action" {
  enum PolicyActionSubType {
    suggest_fix = "suggest_fix"
  }
}

(PolicyActionSubType as { suggest_fix: "suggest_fix" }).suggest_fix = "suggest_fix";

export interface PolicyActionSuggestFix extends PolicyAction {
  sub: PolicyActionSubType.suggest_fix;
  integration: {
    orgId: string;
    type: LlmIntegrationType;
  } | null;
}

export const PolicyActionSuggestFix = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.suggest_fix
  } as const,
  generateDefault(): PolicyActionSuggestFix {
    return {
      sub: PolicyActionSubType.suggest_fix,
      id: `${PolicyItemType.action}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.action,
      name: "",
      integration: null
    };
  },
  is: (action: PolicyAction): action is PolicyActionSuggestFix => {
    return action.sub === PolicyActionSubType.suggest_fix;
  }
} as const;
