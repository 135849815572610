import { CommunicationIntegrationType, IntegrationType, SlackNotificationActionTypes, type ScmIntegrationType } from "./dynamo";
import { FindingBase } from "./dynamo/findingBase";
import { Identity } from "./finding-history-identity";
import { OrgUserIdentity } from "./interfaces/assets/org-user-identity";
import { Metadata } from "./interfaces/ui-api/response/get-secrets-response";
import { GitResourceBase } from "./report/interfaces/git-resource-base";
import { RiskSeverity } from "./risk-severity";

export enum SecretFindingType {
  SECRET = "SECRET"
}

export enum AnomalyFindingType {
  INFERENCE = "INFERENCE",
  CORRELATION = "CORRELATION"
}

export enum CodeRiskFindingType {
  SAST = "SAST",
  SCA = "SCA",
  IAC = "IAC",
  LICENSE = "LICENSE",
  REPUTATION = "REPUTATION"
}

export enum AggregateFindingType {
  AGGREGATE = "AGGREGATE"
}

export enum FindingActivity {
  SLACK_DM = "SLACK_DM",
  SLACK_CHANNEL = "SLACK_CHANNEL",
  MSTEAMS_DM = "MSTEAMS_DM",
  MSTEAMS_CHANNEL = "MSTEAMS_CHANNEL",
  PR_COMMENT = "PR_COMMENT",
  FAILED_STATUS_CHECK = "FAILED_STATUS_CHECK"
}

export type ScannerType = "semgrep" | "trivy" | "checkov" | "driller";

export const DashboardFindingType = {
  PERMISSIONS: "PERMISSIONS",
  HARDENING: "HARDENING",
  ...SecretFindingType,
  ...CodeRiskFindingType
} as const;

export type DashboardFindingType = (typeof DashboardFindingType)[keyof typeof DashboardFindingType];

export type FindingType = SecretFindingType | CodeRiskFindingType | AggregateFindingType;

export const FindingType = {
  ...SecretFindingType,
  ...CodeRiskFindingType,
  ...AggregateFindingType
};

export const FindingTypes = Object.values(FindingType) as FindingType[];

export const MetadataOptions = {
  SECRET_DETECTION: "secret_detection",
  SECRET_MITIGATION: "secret_mitigation",
  ANOMALOUS_COMMIT: "anomalous_commit",
  //TODO: what is the granularity? we have "CodeRisk" at top, then "SAST"/"SCA"/"IaC", then for each, the scanner used, e.g. "Semgrep" / "Checkov" / "Trivy"
  CODE_RISK: "code_risk"
} as const;
export type MetadataOptionsTypes = (typeof MetadataOptions)[keyof typeof MetadataOptions];

export interface SecretsMetadata {
  additionalInfo: string;
  history: FindingsHistory<SecretsHistoryType>[];
  status: SecretStatesType;
  type: MetadataOptionsTypes;
}

export const CommonHistoryStatus = {
  /**
   * @deprecated use NOTIFICATION_SENT
   */
  NOTIFIED_IN_INTEGRATION: "notified_in_integration",
  /**
   * @deprecated use NOTIFICATION_SENT
   */
  SENT_INTERACTIVE_MESSAGE_IN_INTEGRATION: "sent_interactive_message_in_integration",
  RE_DETECTED_IN_NEW_BRANCH: "re_detected_in_new_branch",
  NOTIFICATION_SENT: "notification_sent",
  ISSUE_CREATED: "issue_created"
} as const;

export const CommonDismissOptions = {
  RESOLVED: "resolved", //REVIEW: is "resolved" a DISMISS option?
  IN_PROGRESS: "in_progress",
  DISMISS_NO_CAPACITY: "dismiss_no_capacity",
  DISMISS_NOT_ACCURATE: "dismiss_not_accurate",
  /**
   * Legacy name, means "new" or "open" has nothing to do with requiring review other than yeah,
   * it's new, so it requires someone to review it duh.
   */
  REQUIRES_REVIEW: "requires_review", // TODO: are these 2 actually DISMISS options?
  /**
   * The Actual awaiting someone to review the suggested dismissal (should be read as PENDING_REVIEW_OF_STATUS_CHANGE)
   */
  PENDING_REVIEW: "pending_review" // TODO: are these 2 actually DISMISS options?
} as const;
export type CommonDismissOptionsTypes = (typeof CommonDismissOptions)[keyof typeof CommonDismissOptions];

export const SecretDismissOptions = {
  DISMISS_SECRET_NOT_USED: "dismiss_secret_not_used",
  DISMISS_RISK_IS_TOLERABLE: "dismiss_risk_is_tolerable",
  ...CommonDismissOptions
} as const;
export type SecretDismissOptionsType = (typeof SecretDismissOptions)[keyof typeof SecretDismissOptions];

export const SecretMitigationStatus = {
  VERIFIED_SECRET_RECEIVED: "verified_secret_received",
  NOTIFIED_AWAITING_INPUT: "notified_awaiting_input",
  FIX_IT_FOR_ME_PENDING: "fix_it_for_me_pending",
  FIX_IT_MYSELF: "fix_it_myself",
  FIX_IT_FOR_ME_SUCCESS: "fix_it_for_me_success",
  FIX_IT_FOR_ME_FAILURE: "fix_it_for_me_failure"
} as const;

export type SecretMitigationStatusType = (typeof SecretMitigationStatus)[keyof typeof SecretMitigationStatus];

export const SecretStates = {
  //HISTORICAL_SECRET_FOUND: "historical_secret_found",
  AWAITING_USER_INPUT: "awaiting_user_input",
  MITIGATION_IN_PROGRESS: "mitigation_in_progress",
  MITIGATION_PENDING_PR: "mitigation_pending_pr",
  MITIGATION_FAILED: "mitigation_failed",
  ...SecretDismissOptions,
  DISMISS_NO_OPTION_CHOSEN_YET: "dismiss_no_option_chosen_yet"
} as const;
export type SecretStatesType = (typeof SecretStates)[keyof typeof SecretStates];

export const CodeRiskDismissOptions = {
  RISK_ACCEPTED: "risk_accepted",
  ...CommonDismissOptions
} as const;
export type CodeRiskDismissOptionsTypes = (typeof CodeRiskDismissOptions)[keyof typeof CodeRiskDismissOptions];

/**
 * @deprecated use CodeRiskStates
 * @see CodeRiskStates
 */
export const SemgrepStates = {
  RESOLVED_AUTOMATICALLY: "resolved_automatically",
  DISMISSED_AUTOMATICALLY: "dismissed_automatically",
  ...CodeRiskDismissOptions
} as const;

//TODO: add SCA
export type CodeRiskStates = SemgrepStates;
export const CodeRiskStates = SemgrepStates;
//TODO: rename to CodeRiskStates as these are common with SCA etc.

export type SemgrepStates = (typeof SemgrepStates)[keyof typeof SemgrepStates];
// same for now
export type SemgrepHistoryStates = (typeof SemgrepHistoryOptions)[keyof typeof SemgrepHistoryOptions];
export type CodeRiskHistoryStates = SemgrepHistoryStates;

export type FindingStatuses = CodeRiskStates | SecretStatesType;

export const SemgrepHistoryOptions = {
  COMMITTED: "committed",
  FIRST_DETECTED: "first_detected",
  DETECTED_IN_DEFAULT_BRANCH_SLA_STARTED: "detected_in_default_branch_sla_started",
  DETECTED_IN_SLA_BRANCH_SLA_STARTED: "detected_in_sla_branch_sla_started",
  RESOLVED_IN_BRANCH: "resolved_in_branch",
  UNRESOLVED_IN_BRANCH: "unresolved_in_branch",
  RISK_CHANGED: "risk_changed",
  DECISION: "decision",
  COMMENTED_IN_PR: "commented_in_pr",
  RECOGNIZED_IN_PR: "recognized_in_pr",
  FAILED_STATUS_CHECK: "failed_status_check",
  GENERATED_PR: "generated_pr",
  ...CommonHistoryStatus,
  ...SemgrepStates
  //TODO: add a history state for first detected
  //TODO: add a history state for commit time
} as const;

export const CodeRiskHistoryOptions = SemgrepHistoryOptions;
export const SecretHistoryOptions = {
  ...CommonHistoryStatus,
  COMMIT_CREATED: "commit_created",
  SECRET_DETECTED: "secret_detected",
  DECISION_MADE_FIX_IT_MYSELF: "decision_made_fix_it_myself",
  DECISION_MADE_FIX_IT_FOR_ME: "decision_made_fix_it_for_me",
  FIX_IT_FOR_ME_SUCCESS: "fix_it_for_me_success",
  BRANCH_MERGE_FAILED_PR_CREATED: "branch_merge_failed_pr_created",
  MITIGATION_STARTED: "mitigation_started",
  MITIGATION_FAILED: "mitigation_failed",
  // MITIGATION_COMPLETED: "mitigation_completed",
  BRANCH_RESET: "branch_reset",
  DECISION: "decision",
  ...SecretDismissOptions,
  DISMISS_NO_OPTION_CHOSEN_YET: "dismiss_no_option_chosen_yet",
  REDETECTED_IN_NEW_COMMIT: "redetected_in_new_commit",
  NOTIFICATION_SENT_BRANCH_RESET: "notification_sent_branch_reset"
} as const;
export type SecretsHistoryType = (typeof SecretHistoryOptions)[keyof typeof SecretHistoryOptions];

//TODO: this should be the base for all findings including those without any slack actions, it is not generic enough...
export interface FindingsHistory<H extends HistoryType = HistoryType> {
  /**
   * this is for the SQL migration, used to allow updating an existing history item (only one use case for now)
   */
  id?: string;
  /**
   * For SQL this is the *Finding* sortKey. It's required for magic links.
   */
  sortKey?: string;
  userUpdated?: boolean;
  type: H;
  lastNotifiedDate?: string;
  deciderName?: string | null;
  deciderIdentity?: Identity | null;
  date: string; // DynamoDB date supports ISO 8601 format
  integration?: IntegrationType;
  prLink?: string;
  dismissReason?: string;
  lastEntityContactedDate?: string;
  integrationDisplayName?: string;
  entityIdentifier?: string;
  entityType?: EntityType;
  isPrivate?: boolean;
  pusherIdentity?: string;
  pendingStatus?: H;
  additionalInfo?: string;
  decision?: "accepted" | "rejected" | null;
  suggester?: string;
  suggesterIdentity?: Identity | null;
  suggestedStatus?: H;
  previousStatus?: H;
  resolverIdentity?: OrgUserIdentity | null;
  branch?: string;
  commitHash?: string;
  checkUrl?: string | null;
  targetBranch?: string;
  before?: string;
  commentUrl?: string;
  newSeverity?: RiskSeverity;
  previousSeverity?: RiskSeverity;
  pullRequestId?: number;
  replyUrl?: string;
}

export interface NewHistoryRecord<T extends HistoryType> {
  newStatus: T;
  deciderName?: string | undefined;
  deciderIdentity?: Identity | null;
  dismissReason?: string | undefined;
  pendingStatus?: T;
  userUpdated?: boolean;
  suggestedStatus?: T;
  suggester?: string;
  suggesterIdentity?: Identity | null;
  decisionAccepted?: boolean;
  integration?: CommunicationIntegrationType | ScmIntegrationType;
  replyUrl?: string;
  pullRequestId?: number;
}

export const FindingHistory = {
  createNewHistoryRecord<T extends HistoryType>({
    newStatus,
    deciderName,
    deciderIdentity,
    dismissReason,
    pendingStatus,
    userUpdated = false,
    suggestedStatus,
    suggester,
    suggesterIdentity,
    decisionAccepted,
    integration,
    replyUrl,
    pullRequestId
  }: NewHistoryRecord<T>) {
    const newHistoryEntry: FindingsHistory<T> = {
      date: new Date().toISOString(),
      type: newStatus,
      deciderName,
      deciderIdentity,
      dismissReason,
      userUpdated,
      pendingStatus,
      integration,
      replyUrl,
      pullRequestId
    };
    if (suggester && suggestedStatus) {
      newHistoryEntry.suggester = suggester;
      newHistoryEntry.suggestedStatus = suggestedStatus;
      newHistoryEntry.decision = decisionAccepted ? "accepted" : "rejected";
      newHistoryEntry.suggesterIdentity = suggesterIdentity;
    }

    return newHistoryEntry;
  }
} as const;

export type EntityType = "user" | "channel" | "commit-pusher" | "team";

export const AnomalyDismissOptions = {
  ...CommonDismissOptions
} as const;
export type AnomalyDismissOptionsTypes = (typeof AnomalyDismissOptions)[keyof typeof AnomalyDismissOptions];

export const AnomalyStates = {
  NOTIFIED_IN_INTEGRATION: "notified_in_integration",
  RE_DETECTED_IN_NEW_BRANCH: "re_detected_in_new_branch",
  SENT_INTERACTIVE_MESSAGE_IN_INTEGRATION: "sent_interactive_message_in_integration",
  NOTIFICATION_SENT: "notification_sent",
  ANOMALY_DETECTED: "anomaly_detected",
  ALREADY_CONFIRMED: "already_confirmed",
  AWAITING_USER_INPUT: "awaiting_user_input",
  FLAGGED: "flagged",
  ...AnomalyDismissOptions
} as const;
export type AnomalyStatesType = (typeof AnomalyStates)[keyof typeof AnomalyStates];

/**
 * @deprecated use FindingStatuses
 */
export const CommitStatusStates = { ...SecretStates, ...AnomalyStates, ...CodeRiskStates } as const;

export const FindingStatuses = { ...SecretStates, ...CodeRiskStates } as const;

export type CommitStatusTypes = SecretStatesType | AnomalyStatesType | CodeRiskStates;

export type HistoryType = SecretsHistoryType | AnomalyHistoryType | CodeRiskHistoryStates;

export type FindingHistoryStatuses = SecretsHistoryType | CodeRiskHistoryStates;

export const AnomalyHistoryOptions = {
  ...CommonHistoryStatus,
  COMMIT_CREATED: "commit_created",
  ANOMALY_DETECTED: "anomaly_detected",
  AWAITING_USER_INPUT: "awaiting_user_input",
  NO_INTEGRATION_NOTIFICATIONS_POLICY_ACTIONS: "no_integration_notifications_policy_actions",
  NOT_THAT_USER: "not_that_user",
  IT_WAS_THAT_USER: "it_was_that_user",
  THIS_IS_ME: "this_is_me",
  THIS_WAS_THEM: "this_was_them",
  THIS_WAS_NOT_THEM: "this_was_not_them",
  THIS_IS_NOT_ME: "this_is_not_me",
  ALREADY_CONFIRMED: "already_confirmed",
  NOTIFICATIONS_LIMIT_MET: "notifications_limit_met",
  ...AnomalyDismissOptions
} as const;

/**
 * @deprecated anomalies are deprecated
 */
export type AnomalyHistoryType = (typeof AnomalyHistoryOptions)[keyof typeof AnomalyHistoryOptions];

/**
 * @deprecated anomalies are deprecated
 */
export interface AnomalyHistory {
  userUpdated?: boolean;
  type: AnomalyHistoryType;
  deciderName?: string;
  date: string; // DynamoDB date supports ISO 8601 format
  integration?: "slack";
  prLink?: string;
  dismissReason?: string;
  integrationDisplayName?: string;
  entityIdentifier?: string;
  entityType?: "user" | "channel" | "commit-pusher";
  isPrivate?: boolean;
}

export interface EntitiesContacted extends Omit<SlackEntities, "policyAction" | "identifierType"> {
  entityType: "user" | "channel" | "commit-pusher";
  integrationDisplayName?: string;
  integrationType: "slack";
  isPrivate?: boolean;
}

export interface SlackEntities {
  actionType: SlackNotificationActionTypes.NOTIFY | SlackNotificationActionTypes.SEND_INTERACTIVE_MESSAGE;
  identifierType: string;
  entityIdentifier?: string;
  policyAction: SlackNotificationActionTypes;
  integrationOrgId?: string;
  fallback?: boolean;
}

export const CommonStatusMeanings = ["new", "in_progress", "dismissed", "resolved"] as const;
export type CommonStatusMeanings = (typeof CommonStatusMeanings)[number];

// common to secrets and code risks
export type CommonFindingProps = GitResourceBase &
  FindingBase & {
    risk?: RiskSeverity;
    metadata: Metadata;
    dataType: DashboardFindingType;
    slaStart?: string;
  };

//TODO: this is a convention we have to adhere to!
//TODO: a State should be an object with a "type" property (dismissed, resolved, in_progress, new, reopened, error)
export class StatusUtils {
  public static statusToMeaningMap = new Map<(state: CommitStatusTypes | HistoryType) => boolean, CommonStatusMeanings>([
    [StatusUtils.isDismissed, "dismissed"],
    [StatusUtils.isResolved, "resolved"],
    [StatusUtils.isInProgress, "in_progress"],
    [StatusUtils.isNewOrReopened, "new"],
    [StatusUtils.isError, "in_progress"]
  ]);

  // noinspection JSUnusedLocalSymbols
  private constructor() {
    throw new Error("This class should not be instantiated");
  }

  public static isDismissed(state: CommitStatusTypes | HistoryType): boolean {
    return state.startsWith("dismiss") || state === "risk_accepted";
  }

  public static isResolved(state: CommitStatusTypes | HistoryType): boolean {
    return state.startsWith("resolved") || state === SecretHistoryOptions.FIX_IT_FOR_ME_SUCCESS;
  }

  public static isInProgress(state: CommitStatusTypes | HistoryType): boolean {
    return state.includes("in_progress") || state === "pending_review" || state === "mitigation_started" || state === "mitigation_pending_pr" || state === "mitigation_failed";
  }

  public static isClosed(state: CommitStatusTypes | HistoryType): boolean {
    return StatusUtils.isDismissed(state) || StatusUtils.isResolved(state);
  }

  public static isOpen(state: CommitStatusTypes | HistoryType): boolean {
    return !StatusUtils.isClosed(state);
  }

  public static isNewOrReopened(state: CommitStatusTypes | HistoryType | undefined): boolean {
    if (!state) {
      return false;
    }
    //legacy - secret_detected was used for new secrets for a short while
    return state === "requires_review" || state === SecretHistoryOptions.SECRET_DETECTED || state === SecretStates.AWAITING_USER_INPUT;
  }

  public static isResolutionStatusChangeHistoryItem(historyItem: FindingsHistory<HistoryType>): boolean {
    return (historyItem.type && StatusUtils.isNewOrReopened(historyItem.type)) || StatusUtils.isClosed(historyItem.type);
  }

  public static isAutoResolvable(state: CommitStatusTypes | HistoryType | undefined): boolean {
    if (!state) {
      return false;
    }
    // we only auto resolve "open" findings, or findings that were dismissed automatically (e.g. by the system)
    // the reasoning is, if we are the ones that auto dismissed it (e.g. dev dependencies), we can safely auto resolve it if it no longer exists
    return StatusUtils.isOpen(state) || StatusUtils.isAutoDismissed(state);
  }

  public static isAutoResolved(state: CommitStatusTypes | HistoryType): boolean {
    return state === FindingStatuses.RESOLVED_AUTOMATICALLY;
  }

  public static isAutoDismissed(state: CommitStatusTypes | HistoryType): boolean {
    return state === FindingStatuses.DISMISSED_AUTOMATICALLY;
  }

  /**
   * Either auto dismissed or auto resolved
   * @param state
   */
  public static isAutoClosed(state: CommitStatusTypes | HistoryType): boolean {
    return StatusUtils.isAutoDismissed(state) || StatusUtils.isAutoResolved(state);
  }

  public static isError(state: CommitStatusTypes | HistoryType): boolean {
    return state.includes("error") || state.includes("failed");
  }

  public static getStatusMeaning(status: CommitStatusTypes | HistoryType): CommonStatusMeanings;
  public static getStatusMeaning(status: undefined | null): null;
  public static getStatusMeaning(status: CommitStatusTypes | HistoryType | undefined | null): CommonStatusMeanings | null;
  public static getStatusMeaning(status: CommitStatusTypes | HistoryType | undefined | null): CommonStatusMeanings | null {
    if (!status) {
      return null;
    }

    for (const [checkStatus, meaning] of this.statusToMeaningMap.entries()) {
      if (checkStatus(status)) {
        return meaning;
      }
    }
    return null;
  }
}
