export const SEVERITIES = ["critical", "high", "medium", "low", "info", "unknown"] as const;

export const RISK_CONVERTER: Readonly<Readonly<Record<RiskSeverity, number>> & Readonly<Record<number, RiskSeverity>>> = {
  critical: 1,
  high: 2,
  medium: 3,
  low: 4,
  info: 5,
  unknown: 6,
  1: "critical",
  2: "high",
  3: "medium",
  4: "low",
  5: "info",
  6: "unknown"
} as const;

export type RiskSeverity = (typeof SEVERITIES)[number];

export const RiskSeverity = {
  values: SEVERITIES,
  displayName: {
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
    info: "Info",
    unknown: "Unknown"
  } as const satisfies Record<RiskSeverity, string>,
  map: {
    critical: "Critical 💥",
    high: "High 🚨",
    medium: "Medium ⚠️",
    low: "Low ⬇️",
    info: "Info ℹ️",
    unknown: "Unknown ❓"
  } as const satisfies Readonly<Record<RiskSeverity, string>>,
  is(severity: string | undefined): severity is RiskSeverity {
    return SEVERITIES?.includes(severity as RiskSeverity);
  }
} as const;
