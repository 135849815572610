import { uuid } from "../uuid";
import { BusinessImportanceLabel } from "../../business-importance";
import { UIProductManagement, UIProductAssets } from "./ui-product";
import { UIProductLabel } from "./ui-product-label";
import { UIProductOwnerSummaryDTO } from "./ui-product-owner-summary";
import { UIProductRepositorySummaryDTO } from "./ui-product-repository-summary";
import { UIProductLocationSummaryDTO } from "./ui-product-location-summary";
import { ScmIntegrationType } from "../../dynamo";
import { Lazy } from "../../utility-types";

export interface UIProductSummaryDTO {
  readonly tenantId: string;
  readonly id: uuid;
  readonly name: string;
  readonly management: UIProductManagement;
  readonly hasDeprecatedAssets: boolean;
  readonly lastUpdated: string;
  readonly businessImportance: BusinessImportanceLabel;
  readonly owners: UIProductOwnerSummaryDTO[];
  readonly labels: UIProductLabel[];

  readonly repositoryAssets: UIProductRepositorySummaryDTO[];
  readonly locationAssets: UIProductLocationSummaryDTO[];
}

export interface ProductsList {
  items: UIProductSummaryDTO[];
  count: number;
}

export class UIProductSummary {
  private readonly _assets = new Lazy<UIProductAssets[]>(this.getAssets.bind(this));
  public readonly tenantId: string;
  public readonly id: uuid;
  public readonly name: string;
  public readonly management: UIProductManagement;
  public readonly hasDeprecatedAssets: boolean;
  public readonly lastUpdated: Date;
  public readonly businessImportance: BusinessImportanceLabel;
  public readonly owners: UIProductOwnerSummaryDTO[];
  public readonly labels: UIProductLabel[];
  public readonly repositoryAssets: UIProductRepositorySummaryDTO[];
  public readonly locationAssets: UIProductLocationSummaryDTO[];

  public get securityChampions(): UIProductOwnerSummaryDTO[] {
    return this.owners.filter((o) => o.type === "securityChampion");
  }

  public get productOwners(): UIProductOwnerSummaryDTO[] {
    return this.owners.filter((o) => o.type === "productOwner");
  }

  public get assets(): UIProductAssets[] {
    return this._assets.value;
  }

  private getAssets(): UIProductAssets[] {
    const locations: UIProductAssets = { type: "locations", scm: [] };
    const projects: UIProductAssets = { type: "projects", scm: [] };
    const repositories: UIProductAssets = { type: "repositories", scm: [] };
    const branches: UIProductAssets = { type: "branches", scm: [] };

    for (const location of this.locationAssets) {
      if (location.parentId) {
        projects.scm.push(location.organization.scmType);
      } else {
        locations.scm.push(location.organization.scmType);
      }
    }

    for (const repo of this.repositoryAssets) {
      repositories.scm.push(repo.organization.scmType);
    }
    return [locations, projects, repositories, branches];
  }

  public constructor(dto: UIProductSummaryDTO) {
    Object.assign(this, dto);
    this.lastUpdated = new Date(dto.lastUpdated);
  }
}
