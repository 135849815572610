import { IssueRule } from "../../../shared/src/interfaces/policies/policy-actions/policy-action-create-issue";
import { CreateIssueRule, UpdateIssueRule } from "$/ui-models/products-v2/ui-issue-rule";
import { uuid } from "$/ui-models/uuid";
import { ApiBase } from "./api-base";

export class ProductIssueRulesApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "products", name: "ProductIssueRulesApi" });
  }

  public async createIssueRule(productId: uuid, issueRule: CreateIssueRule): Promise<IssueRule> {
    const res = await this.client.post<IssueRule>(`${productId.encodeURI()}/issue-rules`, issueRule);
    return res.data;
  }

  public async updateIssueRule(productId: uuid, issueRuleId: uuid, issueRule: UpdateIssueRule): Promise<void> {
    await this.client.patch(`${productId.encodeURI()}/issue-rules/${issueRuleId.encodeURI()}`, issueRule);
  }

  public async deleteIssueRule(productId: uuid, issueRuleId: uuid): Promise<void> {
    await this.client.delete(`${productId.encodeURI()}/issue-rules/${issueRuleId.encodeURI()}`);
  }
}
