import { CommonStatusMeanings } from "../../finding-types";
import { RiskSeverity } from "../../risk-severity";
import { uuid } from "../uuid";
import { BusinessImportanceLabel } from "../../business-importance";

export interface UIDashboardHistoryByProductDTO {
  name: string;
  productId: uuid;
  importance: BusinessImportanceLabel;
  date: string;
  total: number;
}

export class UIDashboardHistoryByProduct {
  name: string;
  productId: uuid;
  importance: BusinessImportanceLabel;
  date: Date;
  total: number;

  public constructor(data: UIDashboardHistoryByProductDTO) {
    Object.assign(this, data);
    this.date = new Date(data.date);
  }
}

export interface UIDashboardHistoryByStatusDTO {
  statusMeaning: CommonStatusMeanings;
  date: string;
  total: number;
}

export class UIDashboardHistoryByStatus {
  statusMeaning: CommonStatusMeanings;
  date: Date;
  total: number;

  public constructor(data: UIDashboardHistoryByStatusDTO) {
    Object.assign(this, data);
    this.date = new Date(data.date);
  }
}

export interface UIDashboardHistoryBySeverityDTO {
  severity: RiskSeverity;
  date: string;
  total: number;
}

export class UIDashboardHistoryBySeverity {
  severity: RiskSeverity;
  date: Date;
  total: number;

  public constructor(data: UIDashboardHistoryBySeverityDTO) {
    Object.assign(this, data);
    this.date = new Date(data.date);
  }
}
