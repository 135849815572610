import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    api_initiated_sbom_scan = "api_initiated_sbom_scan"
  }
}

(PolicyTriggerSubType as { api_initiated_sbom_scan: "api_initiated_sbom_scan" }).api_initiated_sbom_scan = "api_initiated_sbom_scan";

export interface PolicyTriggerApiInitiatedSBOMScan extends PolicyTrigger {
  sub: PolicyTriggerSubType.api_initiated_sbom_scan;
}

export const PolicyTriggerApiInitiatedSBOMScan = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.api_initiated_sbom_scan
  },
  generateDefault(): PolicyTriggerApiInitiatedSBOMScan {
    return {
      type: PolicyItemType.trigger,
      sub: PolicyTriggerSubType.api_initiated_sbom_scan,
      id: `${PolicyItemType.trigger}-${Math.floor(Date.now() * Math.random())}`
    };
  }
} as const;
