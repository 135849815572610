// // @spellchecker: ignore papaparse, unparse
import moment from "moment";
import * as papa from "papaparse";
import { getLanguageByPath, ProgrammingLanguage } from "$/utils/file-types";
import { Popup } from "@/state";
import { GitResourceBase } from "$/report/interfaces/git-resource-base";
import { FindingTableParams } from "@/components/findings-table/finding-table-params";
import { CODE_PAGE_NAME } from "$/interfaces/ui-api/response/get-insight-response-item";
import { router } from "@/plugins";

export { getResourceSCMUrl } from "$/utils/links";

function download(url: string, filename: string): void {
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", filename);
    link.click();
  }
}

export function downloadFile(content: string, contentType: string, filename: string) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  download(url, filename);
  URL.revokeObjectURL(url);
}

export function downloadFromUrl(url: URL, filename: string): void {
  download(url.toString(), filename);
}

export function csvExport(rows: Record<string, unknown>[], filename = "export.csv"): void {
  const file = papa.unparse(rows, {
    header: true
  });

  if (!filename.endsWith(".csv")) {
    filename += ".csv";
  }
  downloadFile(file, "text/csv;charset=utf-8;", filename);
}

export function fromNow(date: moment.MomentInput): string {
  return moment(date).fromNow();
}

export function dateFormat(date: moment.MomentInput): string {
  return moment(date).format();
}

export function fallback<T>(value: T | undefined | null, fallbackValue: T): T {
  return value ?? fallbackValue;
}

const extensionsToMaterialDesignIconName: Record<ProgrammingLanguage, LanguageMDIIcon | undefined> = {
  javascript: "mdi-language-javascript",
  react: "mdi-react",
  typescript: "mdi-language-typescript",
  vue: "mdi-vuejs",
  json: "mdi-code-json",
  yaml: "mdi-yaml",
  html: "mdi-language-html5",
  css: "mdi-language-css3",
  scss: "mdi-language-css3",
  sass: "mdi-language-css3",
  less: "mdi-language-css3",
  markdown: "mdi-language-markdown",
  text: "mdi-file-document-outline",
  c: "mdi-language-c",
  "c++": "mdi-language-cpp",
  python: "mdi-language-python",
  java: "mdi-language-java",
  go: "mdi-language-go",
  bash: "mdi-bash",
  ruby: "mdi-language-ruby",
  php: "mdi-language-php",
  "c#": "mdi-language-csharp",
  xml: "mdi-xml",
  sql: "mdi-database",
  rust: "mdi-language-rust",
  kotlin: "mdi-language-kotlin",
  swift: "mdi-language-swift",
  haskell: "mdi-language-haskell",
  lua: "mdi-language-lua",
  docker: "mdi-docker",
  certificate: "mdi-certificate",
  key: "mdi-key",
  terraform: "mdi-terraform",
  serverless: "mdi-serverless",
  "f#": undefined,
  clojure: undefined,
  dart: undefined,
  elixir: undefined,
  elm: undefined,
  erlang: undefined,
  fortran: "mdi-language-fortran",
  groovy: undefined,
  julia: undefined,
  lisp: undefined,
  nim: undefined,
  ocaml: undefined,
  pascal: undefined,
  perl: undefined,
  powershell: undefined,
  r: "mdi-language-r",
  racket: undefined,
  reason: undefined,
  scala: undefined,
  scheme: undefined,
  verilog: undefined,
  vhdl: undefined,
  unknown: "mdi-file-document-outline"
};

export type LanguageMDIIcon =
  | "mdi-language-c"
  | "mdi-language-cpp"
  | "mdi-language-csharp"
  | "mdi-language-css3"
  | "mdi-language-fortran"
  | "mdi-language-go"
  | "mdi-language-haskell"
  | "mdi-language-html5"
  | "mdi-language-java"
  | "mdi-language-javascript"
  | "mdi-language-kotlin"
  | "mdi-language-lua"
  | "mdi-language-markdown"
  | "mdi-language-markdown-outline"
  | "mdi-language-php"
  | "mdi-language-python"
  | "mdi-language-r"
  | "mdi-language-ruby"
  | "mdi-language-ruby-on-rails"
  | "mdi-language-rust"
  | "mdi-language-swift"
  | "mdi-language-typescript"
  | "mdi-language-xaml"
  | "mdi-code-json"
  | "mdi-bash"
  | "mdi-docker"
  | "mdi-database"
  | "mdi-xml"
  | "mdi-file-document-outline"
  | "mdi-react"
  | "mdi-vuejs"
  | "mdi-certificate"
  | "mdi-key"
  | "mdi-terraform"
  | "mdi-serverless"
  | "mdi-yaml";

export function mdiIconByFileExtension(file: string): LanguageMDIIcon {
  if (!file) {
    return "mdi-file-document-outline";
  }
  const { language } = getLanguageByPath(file);

  return extensionsToMaterialDesignIconName[language ?? ""] ?? "mdi-file-document-outline";
}

export function mdiIconByProgrammingLanguage(language?: ProgrammingLanguage): LanguageMDIIcon {
  if (!language) {
    return "mdi-file-document-outline";
  }
  return extensionsToMaterialDesignIconName[language ?? ""] ?? "mdi-file-document-outline";
}

export function joinWithGrammar(strings: string[], lastSeparator = "or"): string {
  const length = strings.length;

  if (length === 0) {
    return "";
  } else if (length === 1) {
    return strings[0]!;
  } else if (length === 2) {
    return `${strings[0]} ${lastSeparator} ${strings[1]}`;
  } else {
    const last = strings[length - 1];
    const remaining = strings.slice(0, length - 1);
    const joined = remaining.join(", ");

    return `${joined}, ${lastSeparator} ${last}`;
  }
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
  void Popup.info("Copied");
}

export function convertToTextColor(color: string) {
  const parts = color.split(" ");
  const ret = `${parts[0]}--text text--${parts[1]}`;
  return ret;
}

export function scrollIntoViewIfNeeded(target: HTMLElement, scrollContainer?: HTMLElement, fixedHeader?: HTMLElement) {
  // if ("scrollIntoViewIfNeeded" in target && typeof target.scrollIntoViewIfNeeded === "function") {
  //   target.scrollIntoViewIfNeeded(false);
  //   return;
  // }

  if (!scrollContainer) {
    scrollContainer = target.parentElement ?? window.document.body;
  }
  const targetRect = target.getBoundingClientRect();
  const containerRect = scrollContainer.getBoundingClientRect();
  const headerRect = fixedHeader?.getBoundingClientRect();
  const top = headerRect?.bottom ?? containerRect.top;
  //TODO: handle v-data-table fixed / sticky header offset
  if (targetRect.top < top) {
    target.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
  }
  const bottom = containerRect.bottom;
  if (targetRect.bottom > bottom) {
    target.scrollIntoView({ block: "end", inline: "end", behavior: "smooth" });
  }
}

export function hexToRgba(hex: string, alpha: number): string {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4 && hex[0] && hex[1] && hex[2] && hex[3]) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7 && hex[1] && hex[2] && hex[3] && hex[4] && hex[5] && hex[6]) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${alpha})`;
}

export function adjustColor(hex: string, factor: number): string {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  if (factor < 0) {
    // Darken
    factor = 1 + factor; // Convert to a factor between 0 and 1
    r = Math.round(r * factor);
    g = Math.round(g * factor);
    b = Math.round(b * factor);
  } else {
    // Lighten
    r = Math.round(r + (255 - r) * factor);
    g = Math.round(g + (255 - g) * factor);
    b = Math.round(b + (255 - b) * factor);
  }

  // Convert back to hex
  const rs = r.toString(16).padStart(2, "0");
  const gs = g.toString(16).padStart(2, "0");
  const bs = b.toString(16).padStart(2, "0");

  return `#${rs}${gs}${bs}`;
}

export function jumpToCodeRisks(resource: GitResourceBase) {
  //TODO: switch to using config instead of direct filter params

  const { branch, integrationOrgId, integrationType, repo, project } = resource;
  const params: FindingTableParams = {
    integrationOrgId,
    integrationType,
    repo,
    project,
    branch
  };

  return router.push({
    name: CODE_PAGE_NAME,
    params: {
      ...params
    },
    query: {
      persist: "false" // Ensure it's a string, as Vue Router treats query params as strings
    }
  });

  /*
      const config: FindingsTableConfig = Object.deepClone(unifiedFindingsTableConfig);
      //findingsTableConfig uses CodeRiskFilters.load() for direct access (so that refreshing the page will persist the filters when going directly)
      //however here we have a very specific filter we want to use
      //eventually, we need 2 filter models, one for "regular" access, and one for links, the links should fully override the "regular" access one
      //the only time we should persist the links filter - is if the user changes it. otherwise, it should be a "one time" filter (taken care of in the findings-table)
      config.filters = new CodeRiskFilters({});

      // in case there is a saved filter for data type, branch etc, remove it
      config.filters.dataType = [CodeRiskFindingType.SCA, CodeRiskFindingType.LICENSE];
      config.filters.branch = [resource.branch].nonNullable();
      config.filters.repo = [resource.repoId ?? resource.repo].nonNullable();
      if(resource.projectId || resource.project) {
        config.filters.project = [resource.projectId ?? resource.project].nonNullable();
      }
      //config.filters.project = [resource.projectId ?? resource.project].nonNullable();
      config.filters.integrationOrgId = [resource.integrationOrgId].nonNullable();
      config.filters.integrationType = [resource.integrationType].nonNullable();

      //return { name: CODE_PAGE_NAME, params: { config } };
      this.$router.push({
        name: CODE_PAGE_NAME,
        params: {
          config,
          product: null
        }
      });
  */
}

export function breakCamelCase(text: string): string {
  return text.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (str) => str.toUpperCase());
}
